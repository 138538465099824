// Protected.js
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./auth-context";

const Protected = ({ children, status }) => {
  const { authState } = useContext(AuthContext);
  console.log(authState);
  console.log("status: " + status);

  if (authState.isLoggedIn) {
    console.log("User is logged in, access granted");
    return children;
  }
  console.log("User not logged in, redirect to login page");
  return <Navigate to='/account/login' />;
};

export default Protected;
