import React from "react";
import FooterLogo from "./FooterLogo";

const Footer = () => {
  return (
    <div className='bg-black'>
      <div
        className='h-[118px] bg-middle bg-center bg-no-repeat bg-[length:75px] text-center'
        style={{ backgroundImage: "url('https://storage.cloud.google.com/foodpop-marketing/homepage-assets/footer-icon.svg')" }}
      >
        {/* <div className='flex justify-center items-center'>
        <div className='flex flex-col sm:flex-row gap-2 sm:gap-4 text-sm'>
          <a href='#' className='py-1 px-2 text-white block text-center sm:inline-block'>
            About
          </a>
          <a href='#' className='py-1 px-2 text-white block text-center sm:inline-block'>
            Contact
          </a>
          <a href='#' className='py-1 px-2 text-white block text-center sm:inline-block'>
            Privacy Policy
          </a>
        </div>
      </div> */}
      </div>
      <div className='text-center'>
        <FooterLogo colour='white' />
      </div>
    </div>
  );
};

export default Footer;
