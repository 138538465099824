import React from "react";
import LocationItem from "./LocationItem";

const LocationList = (props) => {
  return (
    <div>
      {props.locations.map((location) => (
        <LocationItem key={location._id} location={location} business={props.business} />
      ))}
    </div>
  );
};

export default LocationList;
