import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function BlogList() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch("/blogPosts.json")
      .then((response) => response.json())
      .then((data) => setPosts(data));
  }, []);

  return (
    <div className='bg-[#FFF] py-20 text-black'>
      <h2 className='text-2xl mb-4 px-8 font-semibold text-center'>The tastebud.ee blog</h2>
      <p className='text-lg mb-10 px-8 max-w-screen-lg mx-auto text-center'>
        We believe in the importance of highlighting the benefits of displaying allergen's clearly and visibly, even for street traders and market stalls. Take a look at the following in-depth
        articles to help understand the importance of this topic more.
      </p>
      <div className='max-w-screen-lg mx-auto text-left'>
        {posts.length === 0 && <div>Loading...</div>}
        {posts &&
          posts.map((post) => (
            <div key={post.id} className='mb-2'>
              <h2 className='w-full px-2 pb-1 pt-2 text-xl mx-2'>
                <a href={`/blog/${post.id}`}>{post.title}</a>
              </h2>
              <p className='px-2 mx-2'>{post.description}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default BlogList;
