import React from "react";
import MenuItem from "./MenuItem";

const MenuList = (props) => {
  // console.log(props);
  // console.log(props.menu.menuItems);
  return (
    <div>
      {props.menu.menuItems.map((item) => (
        <MenuItem key={item._id} item={item} />
      ))}
    </div>
  );
};

export default MenuList;
