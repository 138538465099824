import React, { useEffect, useState } from "react";
import ButtonAdd from "../../components/buttons/ButtonAdd";
import NoListItems from "../../components/errors/NoListItems";
import BusinessListLocationItem from "./BusinessListLocationItem";
import BusinessListMenuItem from "./BusinessListMenuItem";
import { themes } from "../../utilities/themes";

const BusinessListItem = ({ fetchBusinesses, setEditBusinessInitialValues, toggleBusinessFormOverlay, toggleMenuFormOverlay, toggleLocationFormOverlay, item, updateMenu, changeImage }) => {
  const [theme, setTheme] = useState({});
  // console.log("item");
  // console.log(item);

  // ! DELETE business, refresh and close form
  const deleteBusiness = async (id) => {
    const confirmation = window.confirm("Are you sure you want to delete this business?");
    if (confirmation) {
      console.log("Deleting business");
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/business/${id}`, {
        credentials: "include",
        method: "DELETE",
      });
      /*
    todo: need to add an alert modal (needs component) which double checks deletion
    Also need to make sure this can be used for deleting business, menu and location
    */
      if (response.ok) {
        // console.log(response.data);
        fetchBusinesses();
      } else {
        // console.log(response.data);
        // todo: need to throw an error in here somewhere and display on screen (could be done in the alert component?!)
      }
    } else {
      console.log("Item not deleted");
    }
  };

  // ! Launch edit business form
  const editBusiness = async (id) => {
    /*
    1. Tap the edit button
    2. Launch the business form overlay
      a. Pass initialValues
      b. Pass business ID
    5. Change the mode to "update"
    */
    // Set the initial values in the Edit Business Initial Values state
    setEditBusinessInitialValues({ id: id, data: item });
    toggleBusinessFormOverlay("update");
  };

  const themeObject = themes.find((theme) => theme.name === item.theme);
  if (themeObject) {
    console.log(themeObject);
  } else {
    console.log("Theme not found");
  }

  // console.log(item);
  return (
    <div className='flex flex-row pb-6 mx-4 mb-4 border-gray-50 border-b-4'>
      <div className='w-1/4 mr-4'>
        <div>
          <div className='relative w-32 h-32'>
            <img src={decodeURIComponent(item.businessImage)} className='rounded-full w-32 mb-4' alt={item.businessName + " profile image"} />
            <div
              className='rounded-full absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-50 text-white font-bold transition-opacity duration-300 cursor-pointer'
              onClick={() => changeImage("business", item._id)}
            >
              Change
            </div>
          </div>
          <h3 className='text-xl font-semibold'>{item.businessName}</h3>
          <div className='mb-4'>
            <a href={process.env.REACT_APP_FRONTEND_URL + "/" + item.domainUri} target='_blank' rel='noreferrer' className='underline underline-offset-4 decoration-red-500 tracking-wide'>
              tastebud.ee/{item.domainUri}
            </a>
          </div>
          <p>{item.aboutCopy}</p>
          {themeObject && (
            <div>
              <button onClick={() => editBusiness(item._id)} className={`button-edit bg-[${themeObject?.backgroundColour}] hover:bg-white`}>
                {themeObject.emoji} {item.theme}
              </button>
            </div>
          )}
          <button onClick={() => editBusiness(item._id)} className='button-edit'>
            Edit
          </button>
          <button onClick={() => deleteBusiness(item._id)} className='button-delete'>
            Delete
          </button>
          {/* <QRGenerator value={process.env.REACT_APP_FRONTEND_URL + "/" + item.domainUri} /> */}
        </div>
      </div>
      <div className='w-3/8 mr-4'>
        <div className='w-full mb-2'>
          {item.menus[0] ? (
            item.menus.map((menu) => (
              <BusinessListMenuItem key={menu._id} menu={menu} businessId={item._id} fetchBusinesses={fetchBusinesses} updateMenu={updateMenu} toggleMenuFormOverlay={toggleMenuFormOverlay} />
            ))
          ) : (
            <NoListItems type='menu' />
          )}
        </div>
        <ButtonAdd type='menu' businessId={item._id} toggleMenuFormOverlay={toggleMenuFormOverlay} />
      </div>
      <div className='w-3/8'>
        <div className='w-full mb-2'>
          {item.locations[0] ? (
            item.locations.map((location) => (
              <BusinessListLocationItem
                key={location._id}
                location={location}
                businessId={item._id}
                fetchBusinesses={fetchBusinesses}
                business={item}
                toggleLocationFormOverlay={toggleLocationFormOverlay}
              />
            ))
          ) : (
            <NoListItems type='location' />
          )}
        </div>
        <ButtonAdd type='location' businessId={item._id} toggleLocationFormOverlay={toggleLocationFormOverlay} />
      </div>
    </div>
  );
};

export default BusinessListItem;
