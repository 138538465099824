import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const ImageUpload = ({ imageUploadDetails, closeUploadForm, fetchMenuData, businessId, menuId, fetchBusinesses }) => {
  const [isUploading, setIsUploading] = useState(false);
  //   console.log(imageUploadDetails);
  //   console.log(imageUploadDetails.businessId);

  // Define the validation schema
  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("You need to provide a file")
      .test("fileSize", "File size is too large", (value) => {
        return value && value.size <= 10 * 1024 * 1024;
      }),
  });

  // Define endpoint based on type (business or menuItem)
  let fetchURL = "";
  if (imageUploadDetails.type === "business") {
    // business image
    fetchURL = process.env.REACT_APP_BACKEND_URL + "/upload/businessImage/" + imageUploadDetails.businessId;
  } else if (imageUploadDetails.type === "menuItem") {
    // menu item image
    fetchURL = process.env.REACT_APP_BACKEND_URL + "/upload/menuItemImage/" + businessId + "/" + menuId + "/" + imageUploadDetails.menuItemId;
  }

  // Upload Image
  const uploadImage = async (file) => {
    setIsUploading(true);

    const formData = new FormData();
    formData.append(imageUploadDetails.type === "business" ? "businessImage" : "menuItemImage", file);

    const response = await fetch(fetchURL, {
      credentials: "include",
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      setIsUploading(false); // mark as not uploading
      fetchBusinesses(); // reload the content (irrespective of the page)
      closeUploadForm(); // close the form
    }
  };

  // Close forms if escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeUploadForm();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <h2 className='text-xl text-center mb-6'>{imageUploadDetails.type === "business" ? "Change Business Image" : "Change Menu Item Image"}</h2>
      <p className='mb-4 text-sm'>Choose a replacement image for {imageUploadDetails.name} using the form below.</p>

      <Formik
        initialValues={{ file: null }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          uploadImage(values.file);
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <div>
              <label htmlFor='file'>Choose an image file (maximum, 10Mb):</label>
              <input
                id='file'
                name='file'
                type='file'
                accept='.jpg, .jpeg, .png'
                onChange={(event) => {
                  setFieldValue("file", event.currentTarget.files[0]);
                }}
              />
              {errors.file && touched.file && <div>{errors.file}</div>}
            </div>
            <button type='submit' disabled={isUploading} className='button-standard'>
              {isUploading ? "Uploading..." : "Submit"}
            </button>
            <button className='button-standard' onClick={closeUploadForm} type='button'>
              Close
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ImageUpload;
