// MenuItemForm.js
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { allergensList } from "../../../utilities/allergensList";

const initialValues = {
  itemName: "",
  itemPrice: "",
  itemDescription: "",
  itemIngredients: "",
  itemImage: null,
  itemAllergens: allergensList.map(({ code, name }) => ({
    allergenName: code,
    mayContain: false,
    included: false,
    alternativeAvailable: false,
    alternativeDetails: "",
  })),
};

const validationSchema = Yup.object({
  itemName: Yup.string().required("Please include the menu item name"),
  itemPrice: Yup.number().required("Please include a price").positive("Please include a price of at least 0.01"),
  itemDescription: Yup.string().required("Please add a description to your menu item"),
  itemIngredients: Yup.string().required("Please include the ingredients for your menu item"),
  itemImage: Yup.mixed()
    .required("Item image is required")
    .test("fileSize", "Image too large. Maximum size is 10MB", (value) => !value || (value && value.size <= 10 * 1024 * 1024)),
});

const MenuItemForm = ({ businessId, menuId, fetchMenuData, setShowCreateForm }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    // Create a FormData object
    const formData = new FormData();

    // Append form fields to formData
    formData.append("itemImage", values.itemImage);
    formData.append("itemName", values.itemName);
    formData.append("itemPrice", values.itemPrice);
    formData.append("itemDescription", values.itemDescription);
    formData.append("itemIngredients", values.itemIngredients);

    // Filter itemAllergens
    const filteredAllergens = values.itemAllergens.filter((allergen) => allergen.mayContain || allergen.included);
    formData.append("itemAllergens", JSON.stringify(filteredAllergens));

    // Make the POST request
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value); // log the values of the formData, as it cannot be logged directly
    // }
    console.log(`"Request: " + ${process.env.REACT_APP_BACKEND_URL}/menuitem/${businessId}/${menuId}`);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/menuitem/${businessId}/${menuId}`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    // Handle the response as needed
    if (response.ok) {
      setIsLoading(false);
      console.log("Item added successfully");
      fetchMenuData();
      setShowCreateForm(false);
    } else {
      setIsLoading(false);
      console.error("Error adding item");
    }
  };

  return (
    <div className='bg-white shadow-md rounded p-4 mb-4 border border-gray-200'>
      <h2 className='text-xl inline-block font-semibold mb-2'>Create New Menu Item</h2>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form className='space-y-4'>
            {/* itemName, itemPrice, itemDescription, itemIngredients fields */}
            <div>
              <label htmlFor='itemName' className='block text-sm font-semibold text-gray-700'>
                Item Name
              </label>
              <Field
                type='text'
                name='itemName'
                id='itemName'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-md'
              />
              <ErrorMessage name='itemName' component='div' className='text-red-600 text-sm' />
            </div>

            <div>
              <label htmlFor='itemImage' className='block text-sm font-semibold text-gray-700'>
                Item Image
              </label>
              <input
                type='file'
                accept='.jpg, .jpeg, .png'
                name='itemImage'
                id='itemImage'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-md'
                onChange={(event) => {
                  setFieldValue("itemImage", event.currentTarget.files[0]);
                }}
              />
              <ErrorMessage name='itemImage' component='div' className='text-red-600 text-sm' />
            </div>

            <div>
              <label htmlFor='itemPrice' className='block text-sm font-semibold text-gray-700'>
                Item Price
              </label>
              <Field
                type='number'
                name='itemPrice'
                id='itemPrice'
                step='0.01'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-md'
              />
              <ErrorMessage name='itemPrice' component='div' className='text-red-600 text-sm' />
            </div>

            <div>
              <label htmlFor='itemDescription' className='block text-sm font-semibold text-gray-700'>
                Item Description
              </label>
              <Field
                as='textarea'
                name='itemDescription'
                id='itemDescription'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-md'
              />
              <ErrorMessage name='itemDescription' component='div' className='text-red-600 text-sm' />
            </div>

            <div>
              <label htmlFor='itemIngredients' className='block text-sm font-semibold text-gray-700'>
                Item Ingredients
              </label>
              <Field
                as='textarea'
                type='text'
                name='itemIngredients'
                id='itemIngredients'
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm max-w-md'
              />
              <ErrorMessage name='itemIngredients' component='div' className='text-red-600 text-sm' />
            </div>

            {/* Allergens table */}
            {/*
            Logic:
              * When the "alternativeAvailable" checkbox is checked, the "alternativeDetails" field becomes enabled (already implemented)
              * When the "included" checkbox is checked, the "mayContain" checkbox is automatically checked
              * When the "mayContain" checkbox is unchecked, if the "included" checkbox is checked then it is unchecked. If "included" it is unchecked then it will remain unchecked.
            */}
            <div>
              <h3 className='font-bold mb-2'>Allergens</h3>
              <table className='table-auto w-full'>
                <thead>
                  <tr>
                    <th className='text-left'>Allergen Name</th>
                    <th>May Contain?</th>
                    <th>Included?</th>
                    <th>Alternative Available?</th>
                    <th>Alternative Details?</th>
                  </tr>
                </thead>
                <tbody>
                  {values.itemAllergens.map((allergen, index) => (
                    <tr key={allergen.allergenName} className='table-row'>
                      <td>{allergen.allergenName}</td>
                      <td>
                        <Field
                          type='checkbox'
                          name={`itemAllergens.${index}.mayContain`}
                          className='shadow-none border border-gray-500'
                          onChange={(e) => {
                            setFieldValue(`itemAllergens.${index}.mayContain`, e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Field
                          type='checkbox'
                          name={`itemAllergens.${index}.included`}
                          className='shadow-none border-none'
                          onChange={(e) => {
                            setFieldValue(`itemAllergens.${index}.included`, e.target.checked);
                          }}
                        />
                      </td>
                      <td>
                        <Field type='checkbox' name={`itemAllergens.${index}.alternativeAvailable`} className='shadow-none border-none' />
                      </td>
                      <td>
                        <Field type='text' name={`itemAllergens.${index}.alternativeDetails`} disabled={!values.itemAllergens[index].alternativeAvailable} className='shadow-none' />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Submit button */}
            <button type='submit' className='button-standard' disabled={isLoading}>
              {isLoading ? "Loading..." : "Create"}
            </button>
            <button type='' className='button-standard' onClick={() => setShowCreateForm(false)}>
              Cancel
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MenuItemForm;
