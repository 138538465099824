import React from "react";

const FooterLogo = ({ colour = "black" }) => {
  return (
    <div className={`p-4 text-lg ${colour === "black" ? "text-black" : "text-white"}`}>
      <a href={process.env.REACT_APP_FRONTEND_URL}>
        <span className='font-bold'>taste</span>
        bud.ee
      </a>
    </div>
  );
};

export default FooterLogo;
