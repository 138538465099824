import React from "react";

const About = () => {
  return (
    <div className='mt-14 bg-white flex items-center justify-center mb-10'>
      <div className='px-6 max-w-screen-sm'>
        <h1 className='text-xl font-bold mb-4'>About Taste Bud</h1>
        <p className='mb-4'>As someone who suffers from many different intolerances, and has a love of fresh street and market food, finding something that matches can be difficult.</p>

        <p className='mb-4'>
          There’s always a worry that you’re wasting a vendor’s time when asking so many questions. Sometimes they aren’t that knowledgable or willing to provide alternatives. Most of the time, it’s
          simply a case of wanting to understand all of the options available to you so that you are empowered to make the decision.
        </p>

        <p className='mb-4'>
          Taste Bud was born from the tricky nuances of ordering the freshest street and market food available, hoping to give those in search for a great lunch hope for a tasty meal.
        </p>
      </div>
    </div>
  );
};

export default About;
