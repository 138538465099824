import React from "react";
import FooterLogo from "../../components/FooterLogo";

const Loading = () => {
  return (
    <div className='text-center'>
      <FooterLogo />
      <h1 className='text-2xl font-bold pb-3 px-12 mx-auto'>Menu Loading...</h1>
      <p className='pb-3 px-12 mx-auto'>Please wait whilst we find the menu you're looking for!</p>
    </div>
  );
};

export default Loading;
