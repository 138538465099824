import React from "react";

const AccountLoading = () => {
  return (
    <div className='text-center mb-6'>
      <h1 className='text-2xl font-bold pb-3 px-12 mx-auto'>Account Information Loading...</h1>
      <p className='pb-3 px-12 mx-auto'>Please wait whilst we grab your account information.</p>
    </div>
  );
};

export default AccountLoading;
