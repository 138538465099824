export const currencies = [
  { code: "USD", symbol: "$" },
  { code: "EUR", symbol: "€" },
  { code: "GBP", symbol: "£" },
  // Add more currencies if needed
];

export const getCurrencySymbol = (code) => {
  const currency = currencies.find((currency) => currency.code === code);
  return currency ? currency.symbol : "";
};
