import { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi"; // Using react-icons for the arrows

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);
  return (
    <div className='w-full p-2 border-b'>
      <div className='flex justify-between items-center cursor-pointer' onClick={toggleOpen}>
        <div className='text-xl'>{question}</div>
        <div>
          <button className='text-xl'>{isOpen ? <FiChevronUp /> : <FiChevronDown />}</button>
        </div>
      </div>
      {/* Conditional rendering for the answer */}
      <div className={`${isOpen ? "block" : "hidden"} p-2 text-left`}>{answer}</div>
    </div>
  );
};

export default FAQItem;
