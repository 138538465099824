import React from "react";

/*
Type: "location" or "menu"
*/

const ButtonAdd = ({ type, toggleMenuFormOverlay, toggleLocationFormOverlay, businessId }) => {
  let cta;
  let onClickFunction;

  switch (type) {
    case "menu":
      cta = "Add Menu";
      // console.log("Add Menu");
      // console.log("create");
      // console.log(businessId);
      onClickFunction = () => toggleMenuFormOverlay("create", businessId);
      break;
    case "location":
      cta = "Add Location";
      onClickFunction = () => toggleLocationFormOverlay("create", businessId);
      // todo: needs business ID adding in here for the endpoint
      break;
    default:
      return null;
  }
  return (
    <button onClick={onClickFunction} className='button-standard flex items-center'>
      <span>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='w-5 h-5'>
          <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
        </svg>
      </span>
      {cta}
    </button>
  );
};

export default ButtonAdd;
