import React from "react";
import FooterLogo from "../../components/FooterLogo";

const BusinessNoLocations = () => {
  return (
    <div className='text-center'>
      <FooterLogo />
      <h1 className='text-2xl font-bold pb-3 px-12 mx-auto'>This business isn't ready yet!</h1>
      <p className='pb-3 px-12 mx-auto'>Stay tuned whilst the vendor finishes setting up their business.</p>
    </div>
  );
};

export default BusinessNoLocations;
