import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../auth/auth-context";

const Navbar = () => {
  const { authState, logout } = useContext(AuthContext);

  // const clearToken = JSON.parse(authState.token);
  // const { REACT_APP_BACKEND_URL } = process.env;
  // const LOGOUT_URI = "/users/logout";

  const handleLogout = (event) => {
    event.preventDefault();

    fetch(process.env.REACT_APP_BACKEND_URL + "/users/logout", {
      credentials: "include",
      method: "POST",
      headers: {
        Authorization: `Bearer ${authState.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.message === "Logout Successful") {
          // Set the token in local storage
          console.log(data);
          try {
            console.log("Deleting token");
            logout();
          } catch (e) {
            console.log(e.message);
          }

          // Redirect to homepage
          console.log("Redirecting to homepage, after successful logout");
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  // console.log("domain: " + document.domain);

  // logout("This is the token");
  // console.log(authState);
  return (
    <nav className='flex items-center justify-between flex-wrap bg-white p-6'>
      {/* Name on the left */}
      <div className='flex items-center flex-shrink-0 text-black mr-6'>
        {/* <span className="font-semibold text-xl tracking-tight">Tailwind CSS</span> */}
        <Link to='/'>
          <h1 className='text-xl'>
            <strong>taste</strong>bud.ee
          </h1>
        </Link>
      </div>

      {/* Menu icon in the top right - change hidden to lg:hidden to show again */}
      <div className='block hidden'>
        <button className='flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white'>
          <svg className='fill-current h-3 w-3' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
            <title>Menu</title>
            <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
          </svg>
        </button>
      </div>

      <div className='w-full flex items-center w-auto'>
        <div className='text-sm flex-grow'></div>
        <div className='text-sm'>
          {authState.isLoggedIn && (
            <Link to='/about' className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
              About
            </Link>
          )}
        </div>
        <div className='text-sm'>
          {authState.isLoggedIn && (
            <Link to='/pricing' className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
              Pricing
            </Link>
          )}
        </div>
        <div className='text-sm'>
          <Link to='/contact' className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
            Contact
          </Link>
        </div>
        <div className='text-sm'>
          {authState.isLoggedIn && (
            <Link to='/account/my-account' className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
              My Account
            </Link>
          )}
        </div>
        <div className='text-sm'>
          {!authState.isLoggedIn && (
            <Link to='/account/register' className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
              Register
            </Link>
          )}
        </div>
        <div className='text-sm'>
          {!authState.isLoggedIn && (
            <Link to='/account/login' className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
              Login
            </Link>
          )}
        </div>
        <div className='text-sm'>
          {authState.isLoggedIn && (
            <Link to='' onClick={handleLogout} className='block mt-4 lg:inline-block lg:mt-0 text-black hover:text-cyan-600 mr-4'>
              Logout
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
