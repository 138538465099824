import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// ? This is for creating and renaming the menu

const MenuForm = ({ closeForm, fetchBusinesses, mode, createMenu, updateMenu, updateMenuInitialValues, setupdateMenuInitialValues, menuBusinessId }) => {
  // Close forms if escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Escape" || event.code === "Escape") {
      closeForm();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    menuName: Yup.string().required("Menu name is required"),
  });

  // Define the form fields
  const formFields = [{ name: "menuName", label: "Menu Name", type: "text", placeholder: "Summer Specials" }];

  // Helper function to render the form fields
  const renderFields = ({ errors, touched }) => {
    return formFields.map((field) => {
      return (
        <div key={field.name} className='form-group mb-6'>
          <div>
            <label htmlFor={field.name} className='text-sm font-semibold'>
              {field.label}
            </label>
            {field.description && (
              <p className='text-xs mb-1 text-gray-600' id={field.name}>
                {field.description}
              </p>
            )}
          </div>
          <Field
            id={field.name}
            name={field.name}
            type={field.type}
            as={field.type === "textarea" ? "textarea" : undefined}
            placeholder={field.placeholder}
            className={"form-control" + (errors[field.name] && touched[field.name] ? " is-invalid" : "")}
            autoComplete='off'
            autoCorrect='off'
            rows={field.rows}
            maxLength={field.maxLength}
          />
          <ErrorMessage name={field.name} component='div' className='invalid-feedback text-sm text-red-500' />
        </div>
      );
    });
  };

  // Define the form's initial values and submit function
  let initialFormValues = {
    menuName: "",
  };

  if (mode === "update" && updateMenuInitialValues) {
    initialFormValues = {
      ...initialFormValues,
      menuName: updateMenuInitialValues.menuItem?.menuName || "",
    };
  }

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setIsLoading(true);

      if (mode === "create") {
        // if it's a creation form, then create a business
        await createMenu(values, menuBusinessId);
      } else {
        // if it's an update form, then update the business
        console.log(menuBusinessId, updateMenuInitialValues.menuId, values);
        await updateMenu(menuBusinessId, updateMenuInitialValues.menuId, values);
      }
      setIsLoading(false);
      fetchBusinesses();
      closeForm();
    } catch (err) {
      setIsLoading(false);
      setErrors(err);
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, touched }) => (
        <div className='bg-white p-6 w-96'>
          <h2 className='text-xl text-center mb-6'>{mode === "create" ? "Create a new" : "Update your"} Menu</h2>
          <p className='mb-4 text-sm'>Please choose a name for your menu.</p>
          <Form>
            {renderFields({ errors, touched })}
            <button type='submit' disabled={isLoading} className='button-standard'>
              {isLoading ? "Loading..." : mode === "create" ? "Create" : "Update"}
            </button>
            <button className='button-standard' onClick={closeForm} type='button'>
              Close
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default MenuForm;
