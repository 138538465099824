import React from "react";
import ButtonAdd from "../buttons/ButtonAdd";

const NoListItems = ({ type }) => {
  let message;
  let buttonType = type;

  switch (type) {
    case "menu":
      message = "No menus can be found. Add your first menu, before a location.";
      break;
    case "location":
      message = "No locations can be found.";
      break;
    default:
      return null;
  }

  return (
    <>
      <div className='text-gray-500 text-center py-2 px-4 w-full border text-center'>{message}</div>
    </>
  );
};

export default NoListItems;
