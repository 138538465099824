import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";

const createCheckoutSession = process.env.REACT_APP_BACKEND_URL + "/create-checkout-session";
const billingInformationURL = process.env.REACT_APP_BACKEND_URL + "/create-portal-session";

const postCheckoutSession = async (lookupKey) => {
  const response = await fetch(createCheckoutSession, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ lookup_key: lookupKey }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

// ! Display this by default

const ProductDisplay = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const lookupKey = process.env.REACT_APP_STRIPE_PRODUCT_1;
      const data = await postCheckoutSession(lookupKey);

      // Handle the response data here
      console.log(data);
    } catch (e) {
      // Handle the error here
      console.log(e.message);
    }
  };

  return (
    <div className='mt-14 bg-white flex items-center justify-center'>
      <div className='px-6 max-w-screen-sm'>
        <h1 className='text-xl font-bold mb-4'>Subscription Plans</h1>
        <p className='mb-4'>Please select a subscription to get started with Taste Bud. We currently offer a single subscription as we grow the product.</p>

        <div className='p-2 md:p-0 grid grid-cols-1 md:grid-cols-1 gap-6'>
          {/* Price 1 */}
          <div className='bg-white border-gray-50 shadow-2xl shadow-gray-400 max-w-md mx-auto w-72'>
            <div className=' border-b-gray-800 border-b p-6 bg-[#7BC043]'>
              <h2 className='text-lg font-bold'>Standard Plan</h2>
              <p className='text-sm w-full'>All of our features included.</p>
            </div>
            <div className=' px-6 py-3'>
              <h3 className='text-xl font-light py-2'>
                <span className='text-3xl'>£5.00</span> / month
              </h3>
              <p className='uppercase text-sm text-gray-600 pt-4'>Includes:</p>
              <ul className='list-disc list-inside mt-2 ml-4 text-sm leading-6 text-gray-600'>
                <li>Unlimited Locations</li>
                <li>Unlimited Menus</li>
                <li>Choose your theme</li>
                <li>Support in getting started</li>
                <li>30 day money back guarantee</li>
                {/* <li>14 day free trial</li> */}
              </ul>
              <form action={createCheckoutSession} method='POST'>
                {/* Add a hidden field with the lookup_key of your Price */}
                <input type='hidden' name='lookup_key' value={process.env.REACT_APP_STRIPE_PRODUCT_1} />
                <button id='checkout-and-portal-button' type='submit' className='button-full'>
                  Subscribe
                </button>
                {/* <p className='mt-2 text-center text-sm text-gray-500'>14-day free trial included</p> */}
              </form>
            </div>
          </div>
        </div>
        <p className='m-10 text-center'>Please get in touch if you would like to discuss custom packages and pricing.</p>
      </div>
    </div>
  );
};

// ! Display this upon success

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className='product Box-root'>
        <div className='description Box-root'>
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action={billingInformationURL} method='POST'>
        <input type='hidden' id='session-id' name='session_id' value={sessionId} />
        <button id='checkout-and-portal-button' type='submit'>
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Subscription() {
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState("");

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  if (!success && message === "") {
    return <ProductDisplay />;
  } else if (success && sessionId !== "") {
    return <SuccessDisplay sessionId={sessionId} />;
  } else {
    return <Message message={message} />;
  }
}
