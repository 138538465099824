import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MenuItem from "./MenuItem";
import MenuItemForm from "./MenuItemForm";
import ImageUpload from "../../../forms/ImageUpload";

const Menu = () => {
  const [menuIsLoading, setMenuIsLoading] = useState(true);
  const [menuData, setMenuData] = useState({});
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [isImageUpdateFormVisible, setIsImageUpdateFormVisible] = useState(false);
  const [imageUploadDetails, setImageUploadDetails] = useState({});

  const params = useParams();

  const fetchMenuData = async () => {
    setMenuIsLoading(true);
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/menu/" + params.businessId + "/" + params.menuId, {
      credentials: "include",
      method: "GET",
    });
    const data = await response.json();
    console.log(data);
    if (response.ok) {
      setMenuData(data);
      setMenuIsLoading(false);
    }
  };

  const deleteMenuItem = async (menuItemId) => {
    const confirmation = window.confirm("Are you sure you want to delete this menu item?");
    if (confirmation) {
      console.log("Delete: " + params.businessId + " " + params.menuId + " " + menuItemId);
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/menuitem/" + params.businessId + "/" + params.menuId + "/" + menuItemId, {
        credentials: "include",
        method: "DELETE",
      });
      const data = await response.json();
      // todo: NEED NEED to delete the menu image in GCP
      console.log(data);
      if (response.ok) {
        fetchMenuData();
      }
    } else {
      console.log("Not deleted!");
    }
  };

  const changeImage = (type, menuItemId, name) => {
    setImageUploadDetails({ type, menuItemId, name });
    setIsImageUpdateFormVisible(true);
  };

  const closeUploadForm = () => {
    console.log("closing upload form");
    setIsImageUpdateFormVisible(false);
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  console.log(params.businessId);
  console.log(params.menuId);

  if (menuIsLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div>
        <div className='pb-4 mx-4'>
          <h1 className='text-2xl inline-block px-2 font-semibold mb-2'>
            <span className='bg-[#FFECDA]'>Menu Builder:</span> <span className=' bg-[#FFFEDA]'>{menuData.menuName}</span>
          </h1>
          <p className='text-sm pb-2'>Add to or remove menu items below</p>
        </div>

        <div className='pb-4 mx-4'>
          {menuData.menuItems[0] ? (
            menuData.menuItems.map((menuItem) => <MenuItem key={menuItem._id} menuItem={menuItem} fetchMenuData={fetchMenuData} deleteMenuItem={deleteMenuItem} changeImage={changeImage} />)
          ) : (
            <div>No menu items added yet</div>
          )}
        </div>
        <div className='pb-4 mx-4'>
          {!showCreateForm && (
            <div>
              <button onClick={() => setShowCreateForm(true)} className='button-no-margin mr-4 md:ml-0 ml-4 mb-4 flex items-center'>
                <span>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='w-5 h-5'>
                    <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                  </svg>
                </span>
                Menu Item
              </button>
            </div>
          )}
          {showCreateForm && (
            <div>
              <span id='menu-form'></span>
              <MenuItemForm businessId={params.businessId} menuId={params.menuId} fetchMenuData={fetchMenuData} setShowCreateForm={setShowCreateForm} />
            </div>
          )}
        </div>
        {isImageUpdateFormVisible && (
          <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto'>
            <div className='p-4 m-auto bg-white'>
              <ImageUpload imageUploadDetails={imageUploadDetails} closeUploadForm={closeUploadForm} fetchMenuData={fetchMenuData} businessId={params.businessId} menuId={params.menuId} />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default Menu;
