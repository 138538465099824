import React from "react";
import { allergensList } from "../../../utilities/allergensList";

const Allergen = ({ allergen }) => {
  const getAllergenNameByCode = (code) => {
    const allergen = allergensList.find((allergen) => allergen.code === code);
    return allergen ? allergen.name : "";
  };

  return (
    <tr>
      <td>{getAllergenNameByCode(allergen.allergenName)}</td>
      <td className='text-center'>{allergen.mayContain ? "Yes" : "No"}</td>
      <td className='text-center'>{allergen.included ? "Yes" : "No"}</td>
      <td className='text-center'>{allergen.alternativeAvailable ? "Yes" : "No"}</td>
      <td>{allergen.alternativeDetails}</td>
    </tr>
  );
};

export default Allergen;
