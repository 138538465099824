import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from "@heroicons/react/20/solid";
import Allergen from "./Allergen";

const MenuItem = ({ menuItem, deleteMenuItem, changeImage, businessId, menuId }) => {
  const [expanded, setExpanded] = useState(false);

  // console.log(menuItem);

  // onClick={() => toggleBusinessFormOverlay("create")}

  return (
    <div className='bg-white shadow-md rounded p-4 mb-4 border border-gray-200'>
      <div className='flex'>
        <div className='text-right w-24 flex-shrink-0'>
          <div className='relative w-20 h-20'>
            <img className='w-20 h-20 object-cover rounded cursor-pointer' src={decodeURIComponent(menuItem.itemImage)} alt={menuItem.itemName} />
            <div
              className='absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 bg-black bg-opacity-50 text-white font-bold transition-opacity duration-300 cursor-pointer'
              onClick={() => changeImage("menuItem", menuItem._id, menuItem.itemName)}
            >
              Change
            </div>
          </div>
        </div>
        <div className='flex-grow'>
          <p className='font-semibold'>{menuItem.itemName}</p>
          <p className='text-sm'>{menuItem.itemDescription}</p>
          <p className='font-bold'>£ {menuItem.itemPrice.toFixed(2)}</p>
          {expanded && (
            <div className='mt-4'>
              <h3 className='font-bold'>Ingredients</h3>
              <p>{menuItem.itemIngredients}</p>
              {menuItem.itemAllergens && menuItem.itemAllergens.length > 0 ? (
                <>
                  <h3 className='font-bold mt-2'>Allergens</h3>
                  <table className='table-auto w-full'>
                    <thead>
                      <tr>
                        <th className='pr-4'>Name</th>
                        <th>May Contain</th>
                        <th>Included</th>
                        <th>Alternative Available</th>
                        <th>Alternative Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {menuItem.itemAllergens.map((allergen) => (
                        <Allergen key={allergen._id} allergen={allergen} />
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <h3 className='font-bold mt-2'>No Allergens</h3>
              )}
            </div>
          )}
        </div>
        <div className='w-8 min-w-8'>
          <button className='text-gray-600 h-20 mr-6' onClick={() => deleteMenuItem(menuItem._id)}>
            <TrashIcon className='h-6 w-6' />
          </button>
        </div>
        <div className='w-8 min-w-8'>
          <button className='text-gray-600 h-20' onClick={() => setExpanded(!expanded)}>
            {expanded ? <ChevronUpIcon className='h-6 w-6' /> : <ChevronDownIcon className='h-6 w-6' />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuItem;
