import { PDFDownloadLink } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import QRGenerator from "../../components/QRGenerator";
import LocationMenuPDF from "../../components/LocationMenuPDF";

const BusinessListLocationItem = ({ location, businessId, fetchBusinesses, business, toggleLocationFormOverlay }) => {
  const locationURL = process.env.REACT_APP_FRONTEND_URL + "/" + business.domainUri + "/" + location.location.uri;
  const businessURL = process.env.REACT_APP_FRONTEND_URL + "/" + business.domainUri;

  const propsForQRCode = {};
  propsForQRCode.id = location._id;
  propsForQRCode.locationURL = locationURL;
  propsForQRCode.name = location.location.name;
  propsForQRCode.uri = location.location.uri;
  propsForQRCode.qrCode = decodeURIComponent(location.qrCode);
  propsForQRCode.businessURL = businessURL;
  propsForQRCode.businessName = business.businessName;

  const what3words = "https://what3words.com/" + location.location.what3words;
  const what3wordsTitle = "Find " + business.businessName + ", " + location.location.name + " on what3words.";

  const downloadName = business.domainUri + "_" + location.location.uri;

  const [deleteLocationInProgress, setDeleteLocationInProgress] = useState(false);

  const deleteLocation = async (businessId, menuId) => {
    const confirmation = window.confirm("Are you sure you want to delete this location?");
    if (confirmation) {
      setDeleteLocationInProgress(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/locations/${businessId}/${menuId}`, {
        credentials: "include",
        method: "DELETE",
      });

      if (response.ok) {
        fetchBusinesses();
        setDeleteLocationInProgress(false);
      } else {
        console.log("Error in deleting location");
      }
    } else {
      console.log("Location not deleted");
    }
  };

  const decodedQRImage = decodeURIComponent(location.qrCode);

  return (
    <div className='rounded-md border border-1 w-full mb-2 px-4 py-2'>
      <div className='w-full flex'>
        <div className='w-2/3'>
          <p className='font-semibold'>
            <a href={locationURL} target='_blank' rel='noreferrer'>
              {location.location.name}
            </a>
          </p>
          <p className='pb-2'>
            {location.location.city}, {location.location.country}
          </p>
          <p className='text-sm'>
            <a href={what3words} title={what3wordsTitle} target='_blank'>
              <span className='text-red-500'>/// </span>
              {location.location.what3words}
            </a>
          </p>
          <div>
            <button className='button-edit' onClick={() => toggleLocationFormOverlay("update", businessId, location._id, location)}>
              Edit
            </button>
            <button className='button-delete' onClick={() => deleteLocation(businessId, location._id)}>
              {deleteLocationInProgress ? "Deleting..." : "Delete"}
            </button>
          </div>
        </div>
        <div className='w-1/3 text-center'>
          <PDFDownloadLink document={<LocationMenuPDF details={propsForQRCode} />} fileName={`${downloadName}-qr-menu.pdf`}>
            <img src={decodedQRImage} alt='QR Code for the location' className='w-20 pb-2 mx-auto' />
            <p className='text-sm'>Download QR Codes</p>
          </PDFDownloadLink>
        </div>
      </div>
    </div>
  );
};

export default BusinessListLocationItem;
