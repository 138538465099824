import { useState, useEffect } from "react";
import { AuthContext } from "./auth-context";

export function AuthProvider(props) {
  const [user, setUser] = useState({});

  // Retrieve the authState from localStorage or use the default values
  const initialAuthState = JSON.parse(localStorage.getItem("authState")) || {
    isLoggedIn: false,
    token: null,
    isSubscribed: false,
  };

  const [authState, setAuthState] = useState(initialAuthState);

  // console.log(authState);

  // Function to update the isSubscribed property
  const setIsSubscribed = (newIsSubscribedValue) => {
    setAuthState((prevState) => ({
      ...prevState,
      isSubscribed: newIsSubscribedValue,
    }));
  };

  // Function to update the isLoggedIn property
  const setIsLoggedIn = (newIsLoggedInValue) => {
    setAuthState((prevState) => ({
      ...prevState,
      isLoggedIn: newIsLoggedInValue,
    }));
  };

  const fetchUser = async () => {
    // fetch information about whether the user is subscribed
    try {
      // console.log("start fetching user");
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/users/me", {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      // console.log(data);
      setUser(data);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    // Grab user information
    fetchUser();
    // console.log("fetching user details");
    // check if a token is stored in local storage
    const token = localStorage.getItem("token");
    if (token) {
      setAuthState((prevState) => ({
        ...prevState,
        isLoggedIn: true,
        token,
      }));
    }
  }, []);

  // Save authState to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("authState", JSON.stringify(authState));
  }, [authState]);

  useEffect(() => {
    // Update authState when user changes
    if (user.email) {
      setIsLoggedIn(true);
      setIsSubscribed(user.stripeSubscriptionIsActive);
    }
  }, [user]);

  function login(token, user) {
    // console.log("Logging In");
    localStorage.setItem("token", JSON.stringify(token));
    setAuthState((prevState) => ({
      ...prevState,
      isLoggedIn: true,
      token,
    }));
    // console.log(authState);
  }

  function logout() {
    // console.log("Logged Out");
    localStorage.removeItem("token");
    setAuthState({
      isLoggedIn: false,
      token: null,
    });
  }

  return <AuthContext.Provider value={{ authState, login, logout, user }}>{props.children}</AuthContext.Provider>;
}
