import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function BlogPost() {
  const [post, setPost] = useState(null);
  const { articleId } = useParams();

  useEffect(() => {
    console.log(articleId);
    fetch("./blogPosts.json")
      .then((response) => {
        console.log(response);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("Fetched data:", data); // Log the fetched data
        const article = data.find((p) => p.id === articleId);
        if (!article) {
          console.log(`Article with ID ${articleId} not found.`);
          return;
        }
        console.log("Article found:", article); // Log the found article
        setPost(article);
      })
      .catch((error) => {
        console.error("Fetching error:", error);
      });
  }, [articleId]);

  if (!post) return <div>Loading...</div>;

  return (
    <div className='max-w-[640px] mx-auto blog-post'>
      <h1 className='px-4 my-8 text-center text-3xl'>{post.title}</h1>
      <h3 className='subtitle'>{post.description}</h3>
      <div dangerouslySetInnerHTML={{ __html: post.content }} />
    </div>
  );
}

export default BlogPost;
