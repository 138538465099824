import React from "react";
import { Email } from "react-obfuscate-email";

const Contact = () => {
  return (
    <div className='mt-14 bg-white flex items-center justify-center mb-10'>
      <div className='px-6 max-w-screen-sm'>
        <h1 className='text-xl font-bold mb-4'>Contact</h1>
        <p className='mb-4'>
          Whether you need help in getting started or are having problems logging in, please drop us an email using <Email email='hello@tastebud.ee'>hello at tastebud.ee</Email> and we’ll get back to
          you within 24 hours.
        </p>
      </div>
    </div>
  );
};

export default Contact;
