import { Document, Page, Image, Text, View, StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    margin: "1cm",
    flexDirection: "column",
    backgroundColor: "#FFF",
  },
  topsection: {
    height: "14cm",
    width: "19cm",
    border: "1px solid black",
    flexDirection: "row",
  },
  topleft: {
    width: "60%",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  topright: {
    width: "40%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  section2: {
    width: "50%",
    height: "50%",
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  section3: {
    width: "50%",
    height: "50%",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  image: {
    width: "200px",
    height: "200px",
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: "0.25cm",
  },
  location: {
    marginBottom: "1cm",
    fontSize: 12,
  },
  url: {
    fontSize: 12,
  },
  cta: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: "1cm",
  },
});

const LocationMenuPDF = ({ details }) => {
  // console.log(details);
  return (
    <Document>
      <Page style={styles.page}>
        {/* Top section */}
        <View style={styles.topsection}>
          {/* Top Left @ 60% */}
          <View style={styles.topleft}>
            <Text style={styles.title}>{details.businessName}</Text>
            <Text style={styles.location}>{details.name}</Text>
            <Text style={styles.cta}>Scan the code for our allergy and ingredients menu</Text>
            <Text style={styles.url}>{details.businessURL}</Text>
          </View>
          <View style={styles.topright}>{details.qrCode ? <Image src={details.qrCode} style={styles.image} /> : <Text>No QR Code</Text>}</View>
        </View>
        {/* <View style={styles.section2}>
          <View style={styles.sectionborder}>
            <Text>{details.businessName}</Text>
            <Text>{details.name}</Text>
            <Text>Scan the code for our allergy and ingredients menu</Text>
            <Text>{details.businessURL}</Text>
            {details.qrCode ? <Image src={details.qrCode} style={styles.image} /> : <Text>No QR Code</Text>}
          </View>
        </View>
        <View style={styles.section3}>
          <View style={styles.sectionborder}>
            <Text>{details.businessName}</Text>
            <Text>{details.name}</Text>
            <Text>Scan the code for our allergy and ingredients menu</Text>
            <Text>{details.businessURL}</Text>
            {details.qrCode ? <Image src={details.qrCode} style={styles.image} /> : <Text>No QR Code</Text>}
          </View>
        </View> */}
      </Page>
    </Document>
  );
};

export default LocationMenuPDF;
