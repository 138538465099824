import React from "react";

const Pricing = () => {
  return (
    <div className='mt-14 bg-white flex items-center justify-center'>
      <div className='px-6 max-w-screen-sm'>
        <h1 className='text-xl font-bold mb-4'>Pricing</h1>
        <p className='mb-4'>Please select a subscription to get started with Taste Bud. We currently offer a single subscription as we grow the product.</p>

        <div className='p-2 md:p-0 grid grid-cols-1 md:grid-cols-1 gap-6'>
          {/* Price 1 */}
          <div className='bg-white border-gray-50 shadow-2xl shadow-gray-400 max-w-md mx-auto w-72'>
            <div className=' border-b-gray-800 border-b p-6 bg-[#7BC043]'>
              <h2 className='text-lg font-bold'>Standard Plan</h2>
              <p className='text-sm w-full'>All of our features included.</p>
            </div>
            <div className=' px-6 py-3'>
              <h3 className='text-xl font-light py-2'>
                <span className='text-3xl'>£5.00</span> / month
              </h3>
              <p className='uppercase text-sm text-gray-600 pt-4'>Includes:</p>
              <ul className='list-disc list-inside mt-2 ml-4 text-sm leading-6 text-gray-600'>
                <li>Unlimited Locations</li>
                <li>Unlimited Menus</li>
                <li>Choose your theme</li>
                <li>Support in getting started</li>
                <li>30 day money back guarantee</li>
                {/* <li>14 day free trial</li> */}
              </ul>
            </div>
          </div>
        </div>
        <p className='m-10 text-center'>Please get in touch if you would like to discuss custom packages and pricing.</p>
      </div>
    </div>
  );
};

export default Pricing;
