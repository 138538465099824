import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Pages
import LocationList from "./LocationList";
import MenuList from "./MenuList";
import Error from "../static/Error";

// Components
import FooterLogo from "../../components/FooterLogo";
import BusinessDetails from "../../components/BusinessDetails";
import MenuLinkBusiness from "../../components/MenuLinkBusiness";
import MenuLinkFilters from "../../components/MenuLinkFilters";
import BusinessLoading from "./BusinessLoading";
import BusinessNoLocations from "./BusinessNoLocations";
import { themes } from "../../utilities/themes";

// Import sub-components
// Business Header
// Social Media Icons
// List of Locations
// Item Location
// List of Menu Items
// Item Menu
// FoodPop Logo
// About Business

/*
  Components:
  - Business Profile
  - Location List
    - Location Items
  - Menu List
    - Menu Items
  - Footer
  */

const Business = () => {
  const [businessLoading, setBusinessLoading] = useState(true);
  const [businessLoadingError, setBusinessLoadingError] = useState(false);
  const [businessDetails, setBusinessDetails] = useState({});
  const [locations, setLocations] = useState({});
  const [currentLocation, setCurrentLocation] = useState({});
  const params = useParams();

  const websiteTitle = "Visit " + businessDetails.businessName + " website" || "";

  useEffect(() => {
    console.log("Business Loading: " + businessLoading);
    console.log("Business Loading Error: " + businessLoadingError);
  }, [businessLoading, businessLoadingError]);
  // console.log(params.businessUri);

  const businessThemeDetails = themes.find((theme) => theme.name === businessDetails.theme);
  let businessTheme = {};
  if (businessThemeDetails) {
    businessTheme = businessThemeDetails;
  } else {
    businessTheme = {
      name: "cactus yellow",
      backgroundColour: "#FFC701",
      emoji: "🍋🌵",
      backgroundImage: "https://storage.cloud.google.com/foodpop-marketing/themes/yellow-cactus.svg",
    };
  }

  // fetch data for the menu on mount
  useEffect(() => {
    const fetchData = async () => {
      /*
        ! This component needs to update the businessDetails once the data has been fetched.
        ! This component needs to stay isolated
        ! The fetchData needs to be set out as a separate component
      */

      try {
        // console.log("making a request to: " + process.env.REACT_APP_BACKEND_URL + "/business/" + params.businessUri);
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/business/" + params.businessUri, {
          credentials: "include",
          method: "GET",
        });

        if (!response.ok) {
          // ? Response is not OK, set to not loading and set error.
          setBusinessLoading(false);
          setBusinessLoadingError(true);
          throw new Error(`HTTP error: ${response.status}`);
        }

        const data = await response.json();

        if (data.businessImage) {
          data.businessImage = decodeURIComponent(data.businessImage);
        }

        if (data.contactInfo.website) {
          data.contactInfo.website = decodeURIComponent(data.contactInfo.website);
        }
        setBusinessDetails(data);
        if (data.locations) {
          setLocations(data.locations);
        }
        setBusinessLoading(false);
        document.title = data.businessName + " | ⚡️Tastebud.ee";
        // console.log(locations.length);
        if (locations.length > 0) {
          if (params.locationUri) {
            // console.log("CHECK FOR LOCATION URI");
            // console.log(params.locationUri);
            // console.log(locations);
            const location = locations.find((location) => location.location.uri === params.locationUri);
            setCurrentLocation(location);
            // console.log(location);
            // console.log("CHECK COMPLETE");
          }
        }
      } catch (error) {
        console.error(`Something went wrong: ${error.message}`);
      }
    };
    fetchData();
  }, []);

  /*
  1) No Business URI = 404 Page
  2) Business URI + Location URI = Show Single Menu
  3) Business URI + No Locations = Business Profile
  4) Business URI + > 1 Location = Show List of Locations
  */

  if (businessLoading) {
    return <BusinessLoading />;
  } else if (businessLoadingError) {
    return <Error />;
  } else if (!businessDetails.businessName) {
    // 1) No Business URI = 404 Page
    return <Error />;
  } else if (locations.length === 0) {
    // If there are NO locations, show business profile
    return <BusinessNoLocations />;
  } else if (locations.find((obj) => obj.location.uri === params.locationUri)) {
    //
    //
    // ! Single Location Page
    //
    //
    const location = locations.find((obj) => obj.location.uri === params.locationUri);
    const menu = businessDetails.menus.find((obj) => obj._id === location.menuId);
    // console.log(menu);
    return (
      //
      //
      // ! MENU LIST PAGE
      //
      //
      <>
        {/*       
      A - Main Background - min-height, flex-col
        1. Filter / Business Link
        2. Banner
        3. Menu List & Items
        3. Logo
      */}
        <div
          className='text-center bg-bottom bg-no-repeat bg-[length:1000px]'
          style={{
            backgroundImage: `url('${businessTheme.backgroundImage}')`,
            backgroundColor: businessTheme.backgroundColour,
          }}
        >
          <div className='min-h-screen flex flex-col mx-auto'>
            {/* Filters / Business Link */}
            <div className='absolute left-1/2 top-0 transform -translate-x-1/2 w-full flex justify-between items-center p-4 max-w-md'>
              <div className=''>{/* <MenuLinkFilters /> */}</div>
              <div className='flex-grow'></div>
              <div className=''>
                <MenuLinkBusiness />
              </div>
            </div>

            {/* Business Profile */}
            <div className='mx-auto pt-2 flex-grow'>
              <div className='flex-1 px-5 pt-12'>
                <img src={businessDetails.businessImage} className='w-24 h-24 rounded-full mx-auto mb-4' alt={"image of " + businessDetails.businessName} />
                <h1 className='text-xl uppercase font-bold'>{businessDetails.businessName}</h1>
                <p className='text-base mb-0 font-normal uppercase'>{location.location.name}</p>
                <p className='text-xs mb-4 font-normal uppercase'>{location.location.city}</p>
                <MenuList menu={menu} />
              </div>
            </div>

            {/* Footer Logo */}
            <div className='flex justify-center'>
              <FooterLogo />
            </div>
          </div>
        </div>

        {/* The second part of the page with business details */}
        <span id='business-details'></span>
        <BusinessDetails business={businessDetails} location={location} />
      </>
    );
  } else {
    return (
      //
      //
      // ! LOCATION LIST PAGE
      //
      //
      // If there are locations, show the locations
      // add component for header, which can be used for location or menu
      // add component for business footer
      <>
        <div
          className='text-center bg-bottom bg-no-repeat bg-[length:1000px]'
          style={{
            backgroundImage: `url('${businessTheme.backgroundImage}')`,
            backgroundColor: businessTheme.backgroundColour,
          }}
        >
          <div className='mx-auto pt-14 flex flex-col min-h-screen border-1 border border-black'>
            <div className='flex-1 px-5 pb-4'>
              <img src={businessDetails.businessImage} className='w-24 h-24 rounded-full mx-auto mb-4' alt={"image of " + businessDetails.businessName} />
              <h1 className='text-xl uppercase font-bold mb-2'>{businessDetails.businessName}</h1>
              <p className='text-sm mb-4 max-w-md mx-auto'>{businessDetails.aboutCopy}</p>
              <div className='text-sm mb-4'>
                <a href={businessDetails.contactInfo.website} title={websiteTitle} className='underline underline-offset-4'>
                  {businessDetails.contactInfo.website}
                </a>
              </div>
              <p className='text-sm mb-4'>Our location menus:</p>

              <LocationList locations={locations} business={businessDetails} />
            </div>
            <FooterLogo />
          </div>
        </div>
      </>
    );
  }
};

export default Business;
