export const themes = [
  {
    name: "cactus yellow",
    backgroundColour: "#FFC701",
    emoji: "🌵",
    backgroundImage: "https://storage.cloud.google.com/foodpop-marketing/themes/yellow-cactus.svg",
  },
  {
    name: "lettuce green",
    backgroundColour: "#7BC043",
    emoji: "🥬",
    backgroundImage: "https://storage.cloud.google.com/foodpop-marketing/themes/green-oddities.svg",
  },
  {
    name: "funky white",
    backgroundColour: "#F0F1F1",
    emoji: "🌈",
    backgroundImage: "https://storage.cloud.google.com/foodpop-marketing/themes/white-funky.svg",
  },
];
