import React from "react";
import { Link } from "react-router-dom";
import FooterLogo from "../../components/FooterLogo";

const Error = () => {
  return (
    <div className='text-center'>
      <FooterLogo />
      <img src='https://storage.cloud.google.com/foodpop-marketing/static-assets/404-image-min.png' alt='Error' className='mx-auto px-12 pt-12 pb-4' />
      <h1 className='text-2xl font-bold pb-3 px-12 mx-auto'>Oh fluffy penguins!</h1>
      <p className='pb-3 px-12 mx-auto'>The page / menu you are looking for cannot be found</p>
      <p className=' px-12 mx-auto'>
        If you are a vendor,{" "}
        <Link to='/' className='hover:underline'>
          find out more
        </Link>{" "}
        or{" "}
        <Link to='/account/login' className='hover:underline'>
          log in to your account
        </Link>
      </p>
    </div>
  );
};

export default Error;
