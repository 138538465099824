import React, { useState } from "react";
import { Link } from "react-router-dom";

const BusinessListMenuItem = ({ fetchBusinesses, menu, businessId, toggleMenuFormOverlay }) => {
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  // ? Update and Create Menu handled in: Account.js

  // ! Delete MENU
  const deleteMenu = async (businessId, menuId) => {
    const confirmation = window.confirm("Are you sure you want to delete this menu?");
    if (confirmation) {
      setDeleteInProgress(true);
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/menu/${businessId}/${menuId}`, {
        credentials: "include",
        method: "DELETE",
      });

      if (response.ok) {
        fetchBusinesses();
        setDeleteInProgress(false);
      } else {
        console.log("Error in deleting menu");
      }
    } else {
      console.log("Menu not deleted");
    }
  };

  if (menu.length < 1) {
    return <div>nothing</div>;
  } else {
    return (
      <div className='flex flex-col border border-1 rounded-md w-full mb-2 px-4 py-2'>
        <div>
          <p className='font-semibold'>{menu.menuName}</p>
          <p>{menu.menuItems.length} items</p>
        </div>
        <div>
          <button className='button-edit'>
            <Link to={"../account/menu/" + businessId + "/" + menu._id} className='no-underline	'>
              Manage Menu
            </Link>
          </button>
          <button className='button-edit' onClick={() => toggleMenuFormOverlay("update", businessId, menu._id, menu)}>
            Edit
          </button>
          <button className='button-delete' onClick={() => deleteMenu(businessId, menu._id)}>
            {deleteInProgress ? "Deleting..." : "Delete"}
          </button>
        </div>
      </div>
    );
  }
};

export default BusinessListMenuItem;
