import React from "react";
import BusinessListItem from "./BusinessListItem";

const BusinessList = (props) => {
  // console.log(props.businesses);
  if (!Array.isArray(props.businesses)) {
    return <div>Oh dear, something failed</div>;
  } else if (!props.businesses) {
    return <div>Oh dear, something failed</div>;
  } else if (props.businesses.length < 1) {
    return (
      <div className='mx-4 pb-4'>
        <h2 className='text-xl bg-[#FFECDA] inline-block px-2 font-semibold mb-4'>Welcome to tastebud.ee</h2>
        <p className='mb-4 text-sm'>
          To get started, first add the details of your business. Then you can add a menu, and location for your business. From there you can continue to add menus and locations as you require.
        </p>
        <p className='mb-4 text-sm'>If you get stuck, feel free to reach out for some help!</p>
      </div>
    );
  } else {
    return (
      <div>
        {/* This is the header row, which can be ignored on mobile */}
        <div className='flex flex-row pb-4 mx-4'>
          <div className='flex w-1/4 mr-4'>
            <h2 className='text-xl bg-[#FFECDA] inline-block px-2 font-semibold'>My Business</h2>
          </div>
          <div className='flex w-3/8 mr-4'>
            <h2 className='text-xl bg-[#FFECDA] inline-block px-2 font-semibold'>My Menus</h2>
          </div>
          <div className='flex w-3/8'>
            <h2 className='text-xl bg-[#FFECDA] inline-block px-2 font-semibold'>My Locations</h2>
          </div>
        </div>
        {/* Individual business listings */}
        {props.businesses.map((item) => (
          <BusinessListItem key={item._id} item={item} fetchBusinesses={props.fetchBusinesses} {...props} changeImage={props.changeImage} />
        ))}
      </div>
    );
  }
};

export default BusinessList;
