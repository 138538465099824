import { Routes, Route } from "react-router-dom";
import { useContext } from "react";

// Pages
import Home from "./pages/static/Home";
import Login from "./pages/static/Login";
import Account from "./pages/account/Account";
import Business from "./pages/business/Business";
import Error from "./pages/static/Error";
import Register from "./pages/static/Register";
import Subscription from "./pages/static/Subscription";
import Menu from "./pages/account/MenuBuilder/Menu";
import About from "./pages/static/About";
import Pricing from "./pages/static/Pricing";
import Contact from "./pages/static/Contact";
import BlogList from "./pages/static/blog/BlogList";
import BlogPost from "./pages/static/blog/BlogPost";

// import Auth - needs to be authenticated to see this page (subscription page)
// import Subscribed - needs to be subscribed in order to see this page (my account page)

// Components
import Layout from "./pages/Layout";

// Auth component
import { AuthProvider } from "./auth/auth-provider";
import UserLoggedIn from "./auth/UserLoggedIn";
import UserSubscribed from "./auth/UserSubscribed";

// Routes
function App() {
  // console.log(window.location.href);
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/pricing' element={<Pricing />} />

          <Route path='/blog/:articleId' element={<BlogPost />} />
          <Route exact path='/blog' element={<BlogList />} />

          <Route exact path='/account/login' element={<Login />} />
          <Route exact path='/account/register' element={<Register />} />
          <Route
            exact
            path='/account/my-account'
            element={
              <UserSubscribed>
                <Account />
              </UserSubscribed>
            }
          />
          <Route
            exact
            path='/account/subscription'
            element={
              <UserLoggedIn>
                <Subscription />
              </UserLoggedIn>
            }
          />
          <Route exact path='/account/menu/:businessId/:menuId' element={<Menu />} />
        </Route>
        <Route path='/:businessUri/:locationUri' element={<Business />} />
        <Route path='/:businessUri' element={<Business />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
