// Protected.js
import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./auth-context";

const Protected = ({ children, status }) => {
  const { authState } = useContext(AuthContext);
  // console.log(authState);

  if (authState.isSubscribed && authState.isLoggedIn) {
    // console.log("User is subscribed, access granted");
    return children;
  }
  // console.log("User not subscribed, redirect to subscription page");
  return <Navigate to='/account/subscription' />;
};

export default Protected;
