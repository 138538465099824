import React from "react";

// props passed through as business. Use as props.business.xyz...
const BusinessDetails = (props) => {
  const cash = "https://storage.cloud.google.com/foodpop-marketing/static-assets/cash.svg";
  const applePay = "https://storage.cloud.google.com/foodpop-marketing/static-assets/applePay.svg";
  const androidPay = "https://storage.cloud.google.com/foodpop-marketing/static-assets/googlePay.svg";
  const bitcoin = "https://storage.cloud.google.com/foodpop-marketing/static-assets/bitcoin.svg";
  const mastercard = `https://storage.cloud.google.com/foodpop-marketing/static-assets/mastercard.svg`;
  const visa = "https://storage.cloud.google.com/foodpop-marketing/static-assets/visa.svg";
  const instagram = "https://storage.cloud.google.com/foodpop-marketing/static-assets/instagram.svg";
  const tiktok = "https://storage.cloud.google.com/foodpop-marketing/static-assets/tiktok.svg";
  const youtube = "https://storage.cloud.google.com/foodpop-marketing/static-assets/youtube.svg";
  const facebook = "https://storage.cloud.google.com/foodpop-marketing/static-assets/facebook.svg";

  const what3words = "https://what3words.com/" + props.location.location.what3words;
  const what3wordsTitle = "Find " + props.business.businessName + " on what3words.";

  // console.log(props);
  return (
    <div className='bg-white p-6 text-center max-w-md mx-auto'>
      <p className='font-semibold'>About {props.business.businessName}</p>
      <p className='pb-6'>{props.business.aboutCopy}</p>

      <p className='font-semibold'>Opening Hours</p>
      <p className='pb-6'>{props.location.openingHours}</p>

      <p className='font-semibold'>How to find us</p>
      <p>
        <a href={what3words} title={what3wordsTitle} className='underline underline-offset-4 decoration-red-500' target='_blank'>
          <span className='text-red-500'>/// </span>
          {props.location.location.what3words}
        </a>
      </p>
      <p>{props.location.location.name}</p>
      <p className='pb-6'>
        {props.location.location.city}, {props.location.location.country}
      </p>

      <p className='font-semibold'>Payments accepted</p>
      <ul className='flex justify-center pb-6'>
        {props.location.paymentsAccepted && props.location.paymentsAccepted.cash ? (
          <li>
            <img src={cash} className='inline-block align-middle py-2 px-1 h-10' alt='Cash is accepted here' />
          </li>
        ) : null}
        {props.location.paymentsAccepted && props.location.paymentsAccepted.applePay ? (
          <li>
            <img src={applePay} className='inline-block align-middle py-2 px-1 h-10' alt='Apple Pay is accepted here' />
          </li>
        ) : null}
        {props.location.paymentsAccepted && props.location.paymentsAccepted.androidPay ? (
          <li>
            <img src={androidPay} className='inline-block align-middle py-2 px-1 h-10' alt='Google Pay is accepted here' />
          </li>
        ) : null}
        {props.location.paymentsAccepted && props.location.paymentsAccepted.bitcoin ? (
          <li>
            <img src={bitcoin} className='inline-block align-middle py-2 px-1 h-10' alt='Bitcoin is accepted here' />
          </li>
        ) : null}
        {props.location.paymentsAccepted && props.location.paymentsAccepted.mastercard ? (
          <li>
            <img src={mastercard} className='inline-block align-middle py-2 px-1 h-10' alt='Mastercard is accepted here' />
          </li>
        ) : null}
        {props.location.paymentsAccepted && props.location.paymentsAccepted.visa ? (
          <li>
            <img src={visa} className='inline-block align-middle py-2 px-1 h-10' alt='Visa is accepted here' />
          </li>
        ) : null}
      </ul>

      <p className='font-semibold'>Get in touch</p>
      <ul className='flex justify-center pb-6'>
        {props.business.socialMedia.instagram ? (
          <li>
            <a href={decodeURIComponent(props.business.socialMedia.facebook)} target='_blank' rel='noreferrer'>
              <img src={instagram} className='inline-block align-middle py-2 px-2 h-10' alt='Visa is accepted here' />
            </a>
          </li>
        ) : null}
        {props.business.socialMedia.tiktok ? (
          <li>
            <a href={decodeURIComponent(props.business.socialMedia.facebook)} target='_blank' rel='noreferrer'>
              <img src={tiktok} className='inline-block align-middle py-2 px-2 h-10' alt='Visa is accepted here' />
            </a>
          </li>
        ) : null}
        {props.business.socialMedia.youtube ? (
          <li>
            <a href={decodeURIComponent(props.business.socialMedia.facebook)} target='_blank' rel='noreferrer'>
              <img src={youtube} className='inline-block align-middle py-2 px-2 h-10' alt='Visa is accepted here' />
            </a>
          </li>
        ) : null}
        {props.business.socialMedia.facebook ? (
          <li>
            <a href={decodeURIComponent(props.business.socialMedia.facebook)} target='_blank' rel='noreferrer'>
              <img src={facebook} className='inline-block align-middle py-2 px-2 h-10' alt='Visa is accepted here' />
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

export default BusinessDetails;
