import userEvent from "@testing-library/user-event";
import React from "react";
import { Link } from "react-router-dom";

const UserSubscriptionDetails = (props) => {
  return (
    <div className='pb-4 mx-4'>
      <h1 className='text-2xl bg-[#FFFEDA] inline-block px-2 font-semibold mb-2'>My Account</h1>
      <p className='text-sm pb-2'>
        Logged in as {props.user.name} ({props.user.email}).
      </p>
      {props.user.stripeSubscription && (
        <p className='text-sm'>
          You are subscribed to the {props.user.stripeSubscription.productName} plan. <Link to='../account/subscription'>Update your plan</Link>.
        </p>
      )}
    </div>
  );
};

export default UserSubscriptionDetails;
