import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

const Root = () => {
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "production") {
      ReactGA.initialize("G-4H0TL5Q7VE");
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, []);

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);
