import React, { useEffect, useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/auth-context";

const Login = () => {
  const { login } = useContext(AuthContext);

  const { REACT_APP_BACKEND_URL } = process.env;
  const LOGIN_URI = "/users";

  const userRef = useRef();
  // todo: add focus change for error (accessibility)
  // todo: use "error" to show some kind of error to user registering

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    fetch(REACT_APP_BACKEND_URL + LOGIN_URI, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, name }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // todo: if data.code === 11000 then user already exists

        console.log(data.code);
        if (data.code == "11000") {
          setError("Unable to register, please try again.");
          console.log("Unable to register");
          console.log(error);
        }

        if (data.message === "User created") {
          setError("");
          // Set the token in local storage
          console.log(data);
          try {
            console.log("Setting token");
            login(data.token);
          } catch (e) {
            console.log(e.message);
          }

          // Clear the form on success
          setName("");
          setEmail("");
          setPassword("");

          // Redirect to homepage
          console.log("Redirecting to subscription page, after successful registration");
          window.location.href = "/account/subscription";
        } else {
          setError("Unable to register, please try again.");
          console.log(error);
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div className='flex py-10 md:py-20'>
      <div className='flex-1 h-auto'></div>
      <div className='flex-initial h-auto w-96 min-w-100 bg-slate-50 rounded border border-slate-200 p-10 shadow-lg text-center'>
        <h2 className='text-center text-slate-800 font-medium text-xl pb-6'>Create an Account</h2>
        <form onSubmit={handleSubmit}>
          <div className='p-2'>
            <label className='block text-center mb-5 mx-auto'>
              <span className='text-gray-700 text-center mb-4 text-sm'>Full name</span>
              <input
                type='text'
                id='name'
                value={name}
                onChange={(event) => setName(event.target.value)}
                name='name'
                required
                className='px-2 py-1 block text-gray-700 w-72 mx-auto border-[#D6D6D6] bg-white rounded-lg focus:ring-black'
                autoComplete='name'
                ref={userRef}
              />
            </label>
            <label className='block text-center mb-5 mx-auto'>
              <span className='text-gray-700 text-center mb-4 text-sm'>Email address</span>
              <input
                type='email'
                id='email'
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                name='email'
                required
                className='px-2 py-1 block text-gray-700 w-72 mx-auto border-[#D6D6D6] bg-white rounded-lg focus:ring-black'
                autoComplete='email'
                ref={userRef}
              />
            </label>
            <label className='block text-center mb-5 mx-auto'>
              <span className='text-gray-700 text-center mb-4 text-sm'>Password</span>
              <input
                type='password'
                id='password'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                name='password'
                placeholder=''
                required
                className='px-2 py-1 block text-gray-700 w-72 mx-auto border-[#D6D6D6] bg-white rounded-lg focus:ring-black'
                autoComplete='current-password'
              />
            </label>
          </div>
          {/* <div className='p-2 hidden' id='warning-auth'>
            <p className='text-sm text-center mb-4 text-red-500'>The login details you entered are incorrect, please try again, create an account or reset your password.</p>
          </div> */}
          {error && <p className='text-sm text-red-500'>{error}</p>}
          <div className='items-center px-4 py-3'>
            <button type='submit' id='login' className='bg-cyan-800 hover:bg-white text-white hover:text-cyan-800 rounded-md px-6 py-2 hover:border-cyan-800 border border-white'>
              Create Account
            </button>
            <p className='py-3 px-5 m-0 text-sm hover:underline'>
              <a href='/account/login'>Already registered?</a>
            </p>
          </div>
        </form>
      </div>
      <div className='flex-1 h-auto'></div>
    </div>
  );
};

export default Login;
