import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const LocationForm = ({ closeForm, fetchBusinesses, mode, createLocation, updateLocation, updateLocationInitialValues, businessId, getMenuOptions, locationBusinessId }) => {
  // console.log("Loaded");

  // ! GET LIST OF MENUS (for use within location form)
  const [listOfMenus, setListOfMenus] = useState([]);
  const fetchListOfMenus = async () => {
    try {
      console.log("making a request to: " + process.env.REACT_APP_BACKEND_URL + "/menu/" + locationBusinessId);
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/menu/" + locationBusinessId, {
        credentials: "include",
        method: "GET",
      });
      const data = await response.json();
      const formattedResponse = data.map((menu) => ({
        menuName: menu.menuName,
        _id: menu._id,
      }));
      // console.log(formattedResponse);
      setListOfMenus(formattedResponse);
      // console.log(listOfMenus);
    } catch (e) {
      console.log(e.message);
    }
  };

  // Close forms if escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Escape" || event.code === "Escape") {
      closeForm();
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    fetchListOfMenus();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    async function fetchMenuOptions() {
      const options = await getMenuOptions(businessId);
      setMenuOptions(options);
    }
    fetchMenuOptions();
  }, [getMenuOptions, businessId]);

  const validationSchema = Yup.object().shape({
    location: Yup.object({
      name: Yup.string().required("Location name is required"),
      city: Yup.string().required("City is required"),
      country: Yup.string().required("Country is required"),
      what3words: Yup.string().required("What3Words is required"),
    }),
    openingHours: Yup.string().required("Opening hours are required"),
    menuId: Yup.string().required("Menu ID is required"),
    paymentsAccepted: Yup.object({
      applePay: Yup.boolean(),
      androidPay: Yup.boolean(),
      cash: Yup.boolean(),
      visa: Yup.boolean(),
      mastercard: Yup.boolean(),
      bitcoin: Yup.boolean(),
    }),
  });

  const formFields = [
    { name: "location.name", label: "Location Name", type: "text", placeholder: "Things are getting closer" },
    { name: "location.city", label: "City", type: "text", placeholder: "Sheffield" },
    { name: "location.country", label: "Country", type: "text", placeholder: "England" },
    { name: "location.what3words", label: "What3Words", type: "text", placeholder: "duke.offers.moral" },
    { name: "openingHours", label: "Opening Hours", type: "text", placeholder: "Sometimes on the weekends" },
  ];

  const renderFields = ({ errors, touched }) => {
    return formFields.map((field) => (
      <div key={field.name} className='form-group mb-6'>
        <div>
          <label htmlFor={field.name} className='text-sm font-semibold'>
            {field.label}
          </label>
          {field.description && (
            <p className='text-xs mb-1 text-gray-600' id={field.name}>
              {field.description}
            </p>
          )}
        </div>
        <Field
          id={field.name}
          name={field.name}
          type={field.type}
          as={field.type === "textarea" ? "textarea" : undefined}
          placeholder={field.placeholder}
          className={"form-control" + (errors[field.name] && touched[field.name] ? " is-invalid" : "")}
          autoComplete='off'
          autoCorrect='off'
          rows={field.rows}
          maxLength={field.maxLength}
        />
        <ErrorMessage name={field.name} component='div' className='invalid-feedback text-sm text-red-500' />
      </div>
    ));
  };

  const renderPaymentOptions = ({ values }) => {
    return (
      <div className='form-group mb-6'>
        <label className='text-sm font-semibold'>Payments Accepted</label>
        {Object.keys(values.paymentsAccepted).map((key) => (
          <div key={key} className='flex items-center ml-4 mb-2'>
            <Field type='checkbox' name={`paymentsAccepted.${key}`} className='form-checkbox' />
            <label htmlFor={`paymentsAccepted.${key}`} className='ml-2'>
              {` ${key}`}
            </label>
          </div>
        ))}
      </div>
    );
  };

  let initialFormValues = {
    location: {
      name: "",
      city: "",
      country: "",
      what3words: "",
    },
    openingHours: "",
    currency: "",
    menuId: "",
    paymentsAccepted: {
      applePay: true,
      androidPay: true,
      cash: true,
      visa: true,
      mastercard: true,
      bitcoin: false,
    },
  };

  if (mode === "update" && updateLocationInitialValues) {
    initialFormValues = {
      ...initialFormValues,
      ...updateLocationInitialValues.locationItem,
    };
  }

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setIsLoading(true);

      if (mode === "create") {
        await createLocation(values, locationBusinessId);
      } else {
        await updateLocation(locationBusinessId, updateLocationInitialValues.locationItem._id, values);
      }
      setIsLoading(false);
      fetchBusinesses();
      closeForm();
    } catch (err) {
      setIsLoading(false);
      setErrors(err);
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, touched, values }) => (
        <div className='bg-white p-6 w-96'>
          <h2 className='text-xl text-center mb-6'>{mode === "create" ? "Create a new" : "Update your"} Location</h2>
          <p className='mb-4 text-sm'>Please enter the details for your location.</p>
          <Form>
            {renderFields({ errors, touched })}
            {renderPaymentOptions({ values })}
            <div className='form-group mb-6'>
              <label htmlFor='menuId' className='text-sm font-semibold'>
                Menu
              </label>
              <Field as='select' name='menuId' className='form-control'>
                <option value=''>Select a menu</option>
                {listOfMenus.map((menu) => (
                  <option key={menu._id} value={menu._id}>
                    {menu.menuName}
                  </option>
                ))}
              </Field>
            </div>
            <button type='submit' disabled={isLoading} className='button-standard'>
              {isLoading ? "Loading..." : mode === "create" ? "Create" : "Update"}
            </button>
            <button className='button-standard' onClick={closeForm} type='button'>
              Close
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default LocationForm;
