import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { currencies, getCurrencySymbol } from "../utilities/currencies";
import { themes } from "../utilities/themes";

const BusinessForm = ({ mode, editBusinessInitialValues, setEditBusinessInitialValues, updateBusiness, createBusiness, closeForm, fetchBusinesses }) => {
  // Close forms if escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Escape" || event.code === "Escape") {
      closeForm();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  // Define the validation schema for the form
  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Business name is required"),
    domainUri: Yup.string()
      .required("Domain URI is required. No spaces allowed")
      .matches(/^[a-zA-Z0-9]+$/, "Only letters and numbers (0-9, a-z, A-Z) are allowed, no special characters"),
    telephoneNumber: Yup.string()
      .required("Telephone number is required")
      .matches(/^[0-9+\s()]+$/, "Only numbers 0-9, +, space, ( and ) are allowed")
      .min(6, "Telephone number must be at least 6 characters long")
      .max(18, "Telephone number must be no more than 18 characters long"),
    website: Yup.string().required("Website is required"),
    aboutCopy: Yup.string().required("Please add some information about your business"),
    currency: Yup.string().required("Currency is required"),
    businessImage:
      mode === "create"
        ? Yup.mixed()
            .required("Item image is required")
            .test("fileSize", "Image too large. Maximum size is 10MB", (value) => !value || (value && value.size <= 10 * 1024 * 1024))
        : Yup.mixed().test("fileSize", "Image too large. Maximum size is 10MB", (value) => !value || (value && value.size <= 10 * 1024 * 1024)),
  });

  // Define the form fields
  const formFields = [
    { name: "businessName", label: "Business name", type: "text", placeholder: "The Burger Shack" },
    {
      name: "aboutCopy",
      label: "About your business",
      type: "textarea",
      placeholder: "Our family owned burger shack offers the finest grass-fed beef, freshly sourced from the Welsh hills. Served with fries and all the trimmings.",
      description: "Say a few words about why customers should use and trust your business.",
      rows: 5,
      maxLength: 250,
    },
    {
      name: "domainUri",
      label: "Shortcut / URI",
      type: "text",
      placeholder: "theburgershack",
      description: "This will form part of the website address, where your customers will find you. e.g. " + process.env.REACT_APP_FRONTEND_URL + "/mybusiness",
    },
    { name: "currency", label: "Currency", type: "select", options: currencies, description: "Choose your currency. More coming soon!" },
    { name: "telephoneNumber", label: "Telephone Number", type: "text", placeholder: "+44", description: "This is a description.", maxLength: 18 },
    { name: "website", label: "Website URL", type: "text", placeholder: "www.theburgershack.com" },
    { name: "tiktok", label: "TikTok URL (optional)", type: "text", placeholder: "www.tiktok.com/@theburgershack" },
    { name: "instagram", label: "Instagram URL (optional)", type: "text", placeholder: "www.instagram.com/burgershack/" },
    { name: "facebook", label: "Facebook URL (optional)", type: "text", placeholder: "www.facebook.com/burgershack/" },
    { name: "youtube", label: "YouTube URL (optional)", type: "text", placeholder: "www.youtube.com/user/burgershack" },
    {
      name: "businessImage",
      label: "Business Image",
      type: "file",
      accept: ".jpg, .jpeg, .png",
      display: mode === "create", // Add this line
    },
    {
      name: "theme",
      label: "Theme Choice",
      type: "radio",
    },
    ,
  ].filter((field) => field.display !== false); // Add this line to filter out fields with display set to false;

  console.log(themes);
  // Helper function to render the form fields
  const renderFields = ({ errors, touched, setFieldValue, values }) => {
    return formFields.map((field) => {
      if (field.name === "theme") {
        return (
          <div key={field.name} className='form-group mb-6'>
            <label htmlFor={field.name} className='text-sm font-semibold'>
              {field.label}
            </label>
            <div id={field.name} className='theme-radio-group accent-red-300'>
              {themes.map((theme, index) => (
                <label key={index} style={{ backgroundColor: theme.backgroundColour }}>
                  <Field type='radio' name={field.name} value={theme.name} className='theme-radio-button' /> {theme.emoji} {theme.name}
                </label>
              ))}
            </div>
            <ErrorMessage name={field.name} component='div' className='invalid-feedback text-sm text-red-500' />
          </div>
        );
      }
      if (field.type === "file") {
        return (
          <div key={field.name} className='form-group mb-6'>
            <label htmlFor={field.name} className='text-sm font-semibold'>
              {field.label}
            </label>
            <input
              id={field.name}
              name={field.name}
              type={field.type}
              accept={field.accept}
              className={"form-control" + (errors[field.name] && touched[field.name] ? " is-invalid" : "")}
              onChange={(event) => {
                setFieldValue(field.name, event.currentTarget.files[0]);
              }}
            />
            <ErrorMessage name={field.name} component='div' className='invalid-feedback text-sm text-red-500' />
          </div>
        );
      }
      return (
        <div key={field.name} className='form-group mb-6'>
          <div>
            <label htmlFor={field.name} className='text-sm font-semibold'>
              {field.label}
            </label>
            {field.description && (
              <p className='text-xs mb-1 text-gray-600' id={field.name}>
                {field.description}
              </p>
            )}
          </div>
          {field.type === "select" ? (
            <Field as='select' name={field.name} className='form-control'>
              <option value=''>Select a currency</option>
              {field.options.map((option) => (
                <option key={option.code} value={option.code}>
                  {option.code}
                </option>
              ))}
            </Field>
          ) : (
            <Field
              id={field.name}
              name={field.name}
              type={field.type}
              as={field.type === "textarea" ? "textarea" : undefined}
              placeholder={field.placeholder}
              className={"form-control" + (errors[field.name] && touched[field.name] ? " is-invalid" : "")}
              autoComplete='off'
              autoCorrect='off'
              rows={field.rows}
              maxLength={field.maxLength}
            />
          )}
          <ErrorMessage name={field.name} component='div' className='invalid-feedback text-sm text-red-500' />
        </div>
      );
    });
  };

  // Define the form's initial values and submit function
  let initialFormValues = {
    businessName: "",
    domainUri: "",
    aboutCopy: "",
    telephoneNumber: "",
    website: "",
    tiktok: "",
    instagram: "",
    facebook: "",
    youtube: "",
    currency: "",
    theme: themes[0].name,
  };

  if (mode === "update" && editBusinessInitialValues) {
    initialFormValues = {
      ...initialFormValues,
      businessName: editBusinessInitialValues.data?.businessName || "",
      domainUri: editBusinessInitialValues.data?.domainUri || "",
      aboutCopy: editBusinessInitialValues.data?.aboutCopy || "",
      telephoneNumber: editBusinessInitialValues.data?.contactInfo?.telephoneNumber || "",
      currency: editBusinessInitialValues.data?.currency || "",
      website: decodeURIComponent(editBusinessInitialValues.data?.contactInfo?.website) || "",
      tiktok: decodeURIComponent(editBusinessInitialValues.data?.socialMedia?.tiktok) || "",
      instagram: decodeURIComponent(editBusinessInitialValues.data?.socialMedia?.instagram) || "",
      facebook: decodeURIComponent(editBusinessInitialValues.data?.socialMedia?.facebook) || "",
      youtube: decodeURIComponent(editBusinessInitialValues.data?.socialMedia?.youtube) || "",
      theme: editBusinessInitialValues.data?.theme || themes[0].name,
    };
  }

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      // Construct the contactInfo object
      const contactInfo = {
        website: values?.website,
        telephoneNumber: values?.telephoneNumber,
      };

      const socialMedia = {
        tiktok: values?.tiktok,
        instagram: values?.instagram,
        facebook: values?.facebook,
        youtube: values?.youtube,
      };
      console.log(values);
      console.log(contactInfo);
      console.log(socialMedia);

      // Remove the website and telephoneNumber fields from the values object
      delete values.website;
      delete values.telephoneNumber;
      delete values.tiktok;
      delete values.instagram;
      delete values.facebook;
      delete values.youtube;

      // Add the contactInfo object to the values object
      values.contactInfo = contactInfo;
      values.socialMedia = socialMedia;

      // Append fields to formData
      // formData.append("businessImage", values.businessImage);
      // formData.append("contactInfo", values.contactInfo);
      // formData.append("socialMedia", values.socialMedia);
      // formData.append("currency", values.currency);
      // formData.append("businessName", values.businessName);
      // formData.append("domainUri", values.domainUri);
      // formData.append("aboutCopy", values.aboutCopy);
      // formData.append("currency", values.currency);

      // Stringify the contactInfo and socialMedia objects before appending
      values.contactInfo = JSON.stringify(contactInfo);
      values.socialMedia = JSON.stringify(socialMedia);

      // Append all values and the file to formData
      for (const key in values) {
        if (key === "businessImage") {
          formData.append(key, values[key]);
        } else {
          formData.append(key, values[key]);
        }
      }

      if (mode === "create") {
        // if it's a creation form, then create a business
        await createBusiness(formData);
      } else {
        // if it's an update form, then update the business
        await updateBusiness(editBusinessInitialValues.id, values);
      }
      setIsLoading(false);
      fetchBusinesses();
      closeForm();
    } catch (err) {
      setIsLoading(false);
      setErrors(err);
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, touched, setFieldValue }) => (
        <div className='bg-white p-6 w-96'>
          <h2 className='text-xl text-center mb-6'>{mode === "create" ? "Create a new" : "Update your"} Business</h2>
          <p className='mb-4 text-sm'>Please fill out the details of your business below.</p>
          <Form>
            {renderFields({ errors, touched, setFieldValue })}
            <button type='submit' disabled={isLoading} className='button-standard'>
              {isLoading ? "Loading..." : mode === "create" ? "Create" : "Update"}
            </button>
            <button className='button-standard' onClick={closeForm} type='button'>
              Close
            </button>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default BusinessForm;
