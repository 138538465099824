import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import FAQItem from "./components/FAQItem";

const Home = () => {
  const [posts, setPosts] = useState([]);

  const faqs = [
    { question: "How many locations can I create?", answer: "You can add as many locations to your QR code allergen menu as you like." },
    { question: "How many dishes can I add to my QR code menu?", answer: "You can add as many dishes to your QR code allergen menu as you like." },
    { question: "How quickly is my QR code activated?", answer: "As soon as you create your account and add a location, your QR code will be activated." },
    {
      question: "I need some help setting this up, what support do you provide?",
      answer:
        "Within your first 30 days we'll help you get set up, including creating locations, menus and ensuring you're ready and confident to use your QR code with your customers. If you have any specific needs with regards to this, just let us know!",
    },
    {
      question: "How and where can I use my QR code?",
      answer: "You receive a downloadable PDF which you can print, and a downloadable JPG which you can easily incorporate into other documents at whatever size you like.",
    },
    // Add more FAQs here
  ];

  useEffect(() => {
    fetch("/blogPosts.json")
      .then((response) => response.json())
      .then((data) => setPosts(data));
  }, []);

  return (
    <div className='text-center'>
      <div
        className='bg-middle bg-center bg-no-repeat bg-[length:1163px]'
        style={{ backgroundImage: "url('https://storage.cloud.google.com/foodpop-marketing/homepage-assets/homepage-background.svg')" }}
      >
        <img
          src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/hero-image-optimised.png'
          className='mx-auto w-full sm:max-w-[580px] mt-10 mb-14 px-2'
          alt='A set of homepage screens, depicting what three different street vendor menus look like'
        />
        <h1 className='text-4xl max-w-[500px] mx-auto mb-6 text-[#161616] font-bold tracking-normal leading-snug'>Take away the pain of allergies and intolerances</h1>
      </div>
      <div className='text-center max-w-[640px] mx-auto'>
        <h2 className='text-xl mb-12 px-8'>
          Fun, simple and detailed <span className='underline underline-offset-4 decoration-red-500'>QR Code Menus</span> for street vendors, market stall holders and small food venues.
        </h2>
        {/* <h3>Share food ingredients and allergen information, making customers happy and expand your customer base. Optimise your time and optimise your serving capacity.</h3> */}
        <Link to='/account/register'>
          <button className='text-[#FFC701] bg-black rounded-xl font-bold px-6 py-3 mb-3'>Get started for £5 per month</button>
        </Link>
      </div>
      <div className='py-20 text-black'>
        <h2 className='text-2xl mb-10 px-8 font-semibold'>Why use QR code menus?</h2>
        <div className='flex flex-wrap max-w-screen-lg mx-auto text-center'>
          <div className='w-full md:w-1/4 p-2 flex flex-col text-center my-10 md:my-2'>
            <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/lightening-red.png' alt='Red lightening symbol' className='mb-3 mx-auto' />
            <h3 className='text-xl mb-5 text-center'>Confidence</h3>
            <p className='max-w-xs mx-auto'>Give your customers the confidence they need to purchase</p>
          </div>
          <div className='w-full md:w-1/4 p-2 flex flex-col text-center my-10 md:my-2'>
            <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/lightening-white.png' alt='White lightening symbol' className='mb-3 mx-auto' />
            <h3 className='text-xl mb-5 text-center'>Easy to Use</h3>
            <p className='max-w-xs mx-auto'>Optimise your time using self-serve menus during peak trading periods</p>
          </div>
          <div className='w-full md:w-1/4 p-2 flex flex-col text-center my-10 md:my-2'>
            <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/lightening-yellow.png' alt='Yellow lightening symbol' className='mb-3 mx-auto' />
            <h3 className='text-xl mb-5 text-center'>Intuitive</h3>
            <p className='max-w-xs mx-auto'>Straightforward account management with an intuitive interface</p>
          </div>
          <div className='w-full md:w-1/4 p-2 flex flex-col text-center my-10 md:my-2'>
            <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/lightening-green.png' alt='Green lightening symbol' className='mb-3 mx-auto' />
            <h3 className='text-xl mb-5 text-center'>Flexible</h3>
            <p className='max-w-xs mx-auto'>Easily set up multiple businesses, menus and locations, each with their own vibe</p>
          </div>
        </div>
      </div>
      <div className='bg-[#F0F1F1] py-20 text-black'>
        <h2 className='text-2xl mb-4 px-8 font-semibold'>Street food allergen menus</h2>
        <p className='text-lg mb-10 px-8'>Take a look at how tastebud.ee is used by food vendors, either by following the link or scanning the code.</p>
        <div className='flex flex-wrap max-w-screen-lg mx-auto text-center'>
          <div className='w-full md:w-1/3 p-2 flex flex-col text-center my-10 md:my-2'>
            <a href='https://tastebud.ee/thetastythai/market-street' target='_blank' rel='noreferrer' title='Example menu for The Tasty Thai street vendor'>
              <h3 className='text-xl mb-5 text-center'>The Tasty Thai</h3>
              <img
                src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/tasty-thai-street-vendor.png'
                alt='The Tasty Thai street food market stall'
                className='mb-3 mx-auto rounded-full w-48'
              />
              <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/tasty-thai-qr.png' alt='QR code for The Tasty Thai' className='mb-3 mx-auto w-32' />
            </a>
          </div>
          <div className='w-full md:w-1/3 p-2 flex flex-col text-center my-10 md:my-2'>
            <a href='https://tastebud.ee/tokyokitchen/market-street' target='_blank' rel='noreferrer' title='Example menu for Tokyo Kitchen market food stall'>
              <h3 className='text-xl mb-5 text-center'>Tokyo Kitchen</h3>
              <img
                src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/tokyo-kitchen-market-food-stall.png'
                alt='Tokyo Kitchen street food venue'
                className='mb-3 mx-auto rounded-full w-48'
              />
              <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/tokyo-kitchen-qr.png' alt='QR code for Tokyo Kitchen' className='mb-3 mx-auto w-32' />
            </a>
          </div>
          <div className='w-full md:w-1/3 p-2 flex flex-col text-center my-10 md:my-2'>
            <a href='https://tastebud.ee/elchilango/market-street' target='_blank' rel='noreferrer' title='Example menu for El Chilango street food venue'>
              <h3 className='text-xl mb-5 text-center'>El Chilango</h3>
              <img
                src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/el-chilango-street-food-vendor.png'
                alt='El Chilango street food vendor'
                className='mb-3 mx-auto rounded-full w-48'
              />
              <img src='https://storage.cloud.google.com/foodpop-marketing/homepage-assets/el-chilango-qr.png' alt='QR code for El Chilango' className='mb-3 mx-auto w-32' />
            </a>
          </div>
        </div>
      </div>

      {/* Frequently Asked Questions */}
      <div className='bg-[#7BC043] py-20 text-white'>
        <h2 className='text-2xl mb-4 px-8 font-semibold'>Frequently Asked Questions</h2>
        <p className='text-lg mb-10 px-8 mx-auto'>These are some of our most frequently asked questions. However, if we can't answer your questions here, get in touch!</p>
        <div className='flex flex-wrap max-w-screen-lg mx-auto text-center px-4'>
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>

      {/* Blog Post Section */}

      <div className='bg-[#FFF] py-20 text-black'>
        <h2 className='text-2xl mb-4 px-8 font-semibold'>The Importance of QR Code Allergen Menus</h2>
        <p className='text-lg mb-10 px-8 max-w-screen-lg mx-auto'>
          We believe in the importance of highlighting the benefits of displaying allergen's clearly and visibly, even for street traders and market stalls. Take a look at the following in-depth
          articles to help understand the importance of this topic more.
        </p>
        <div className='max-w-screen-lg mx-auto text-left'>
          {posts.length === 0 && <div>Loading...</div>}
          {posts &&
            posts.map((post) => (
              <div key={post.id} className='mb-2'>
                <h2 className='w-full px-2 pb-1 pt-2 text-xl mx-2'>
                  <a href={`/blog/${post.id}`}>{post.title}</a>
                </h2>
                <p className='px-2 mx-2'>
                  {post.date}: {post.description}
                </p>
              </div>
            ))}
        </div>
      </div>

      {/* End */}
    </div>
  );
};

export default Home;
