export const allergensList = [
  {
    code: "celery",
    name: "Celery",
  },
  {
    code: "cerealsContainingGluten",
    name: "Cereals containing gluten",
  },
  {
    code: "crustaceans",
    name: "Crustaceans",
  },
  {
    code: "eggs",
    name: "Eggs",
  },
  {
    code: "fish",
    name: "Fish",
  },
  {
    code: "garlic",
    name: "Garlic",
  },
  {
    code: "lupin",
    name: "Lupin",
  },
  {
    code: "milk",
    name: "Milk",
  },
  {
    code: "molluscs",
    name: "Molluscs",
  },
  {
    code: "mustard",
    name: "Mustard",
  },
  {
    code: "nuts",
    name: "Nuts",
  },
  {
    code: "onions",
    name: "Onions",
  },
  {
    code: "peanuts",
    name: "Peanuts",
  },
  {
    code: "sesame",
    name: "Sesame",
  },
  {
    code: "soybeans",
    name: "Soybeans",
  },
  {
    code: "sulphites",
    name: "Sulphites",
  },
];

export const getAllergenNameByCode = (code) => {
  const allergen = allergensList.find((allergen) => allergen.code === code);
  return allergen ? allergen.name : "";
};
