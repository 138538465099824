import { useState } from "react";
import { allergensList, getAllergenNameByCode } from "../../utilities/allergensList";

const MenuItem = (props) => {
  const [showMore, setShowMore] = useState(false);

  if (props.item.itemActive) {
    // var imgStyle = {
    //   backgroundImage: "url(" + decodeURIComponent(props.item.itemImage) + ")",
    // };

    return (
      <div className='border border-black border-1 bg-white bg-opacity-95 rounded-2xl p-2 block mb-4 text-sm max-w-md mx-auto'>
        {/* start columns */}
        <div className='flex flex-col'>
          {/* start first row */}
          <div className='flex flex-row'>
            <div className='w-1/4'>
              <img src={decodeURIComponent(props.item.itemImage)} className='aspect-square rounded-xl' alt={"Image of " + props.item.itemName} />
            </div>
            <div className='flex flex-col w-3/4 pl-2'>
              <div className='flex flex-row text-lg'>
                <div className={showMore ? "w-3/4 text-left font-bold" : "line-clamp-1 w-3/4 text-left font-bold"}>{props.item.itemName}</div>
                <div className='w-1/4 text-right'>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(props.item.itemPrice)}</div>
              </div>
              <div className='flex flex-row'>
                <div className={showMore ? "text-left pt-2" : "text-left line-clamp-3 pt-2"}>{props.item.itemDescription}</div>
              </div>
              {showMore && (
                <div className='flex flex-row'>
                  <div className='text-left pt-2'>
                    <p className='font-semibold'>Ingredients:</p>
                    <p>{props.item.itemIngredients}</p>
                    {props.item.itemAllergens.some((allergen) => allergen.mayContain) && (
                      <>
                        <p className='font-bold pt-2 pb-2'>May contain:</p>
                        {props.item.itemAllergens
                          .filter((allergen) => allergen.mayContain)
                          .map((allergen) => (
                            <button className='rounded-md border border-gray-300 inline px-2 py-1 mr-1 mb-2 text-gray-700 text-xs hover:text-white hover:bg-gray-600'>
                              {getAllergenNameByCode(allergen.allergenName)}
                            </button>
                          ))}
                      </>
                    )}
                    {props.item.itemAllergens.some((allergen) => allergen.included) && (
                      <>
                        <p className='font-bold pt-2 pb-2'>Allergens included:</p>
                        {props.item.itemAllergens
                          .filter((allergen) => allergen.included)
                          .map((allergen) => (
                            <button className='rounded-sm border border-gray-300 hover:bg-gray-600 inline px-2 py-1 mr-1 mb-2 text-gray-700 hover:text-white text-xs'>
                              {getAllergenNameByCode(allergen.allergenName)}
                            </button>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <button className='uppercase text-xs border border-1 border-black bg-white rounded-full px-2 py-1 z-10 mt-3' onClick={() => setShowMore(!showMore)}>
              {showMore ? "less" : "more"}
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default MenuItem;
