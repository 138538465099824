import { useState, useEffect } from "react";
// import { Navigate } from "react-router-dom";
import BusinessList from "./BusinessList";
import UserSubscriptionDetails from "./UserSubscriptionDetails";

// Forms
import BusinessForm from "../../forms/BusinessForm";
import MenuForm from "../../forms/MenuForm";
import LocationForm from "../../forms/LocationForm";
import ImageUpload from "../../forms/ImageUpload";
import AccountLoading from "./AccountLoading";

const Account = () => {
  // Account Page
  const [myBusinessLoading, setMyBusinessLoading] = useState(true);
  const [myBusinessDetails, setMyBusinessDetails] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});

  // Form overlay state management
  const [isBusinessFormOverlayVisible, setIsBusinessFormOverlayVisible] = useState(false);

  // States for the Business Form
  const [businessFormMode, setBusinessFormMode] = useState("create");
  const [editBusinessInitialValues, setEditBusinessInitialValues] = useState({ id: "", data: {} });

  // ! Form overlay handling
  const toggleBusinessFormOverlay = (mode = "create") => {
    if (mode === "create") {
      console.log("Business form set to create mode");
      setBusinessFormMode("create");
      setEditBusinessInitialValues({ id: "", data: {} });
    } else if (mode === "update") {
      console.log("Business form set to update mode");
      setBusinessFormMode("update");
    }
    console.log("Toggle Business Form");
    setIsBusinessFormOverlayVisible(!isBusinessFormOverlayVisible);
  };

  // Close forms if escape key is pressed
  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      closeAllOverlays();
    }
  };

  // ! fetch BUSINESS data
  const fetchBusinesses = async () => {
    setMyBusinessDetails(true);
    try {
      // console.log("making a request to: " + process.env.REACT_APP_BACKEND_URL + "/business/" + params.businessUri);
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/business", {
        credentials: "include",
        method: "GET",
      });
      const data = await response.json();

      setMyBusinessDetails(data);
      setMyBusinessLoading(false);
      document.title = "My Account | ⚡️Tastebud.ee";
    } catch (error) {
      console.error(`Something went wrong: ${error.message}`);
    }
  };

  // ! fetch USER data
  const fetchUser = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/users/me", {
        credentials: "include",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setUserDetails(data);
      setUserLoading(false);
    } catch (e) {
      console.log(e.message);
    }
  };

  // ! CREATE BUSINESS
  const createBusiness = async (formData) => {
    console.log("Form Data for Create Business");
    console.log(formData);
    try {
      console.log("Attempting to create a new business...");
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/business", {
        credentials: "include",
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log(responseData);
      return responseData;
    } catch (e) {
      console.log(e.message);
    }
  };

  // ! UPDATE BUSINESS
  const updateBusiness = async (id, formData) => {
    console.log("Update business");
    console.log(formData);
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/business/" + id, {
        credentials: "include",
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData), // turn it into a JSON object
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log(responseData);
      return responseData;
    } catch (e) {
      console.log(e.message);
    }
  };

  // & MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU

  // & MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU

  // & MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU MENU

  // States for the Menu Form
  const [menuFormMode, setMenuFormMode] = useState("create");
  const [updateMenuInitialValues, setupdateMenuInitialValues] = useState({ menuBusinessId: "", menuId: "", menuItem: {} });
  const [menuBusinessId, setMenuBusinessId] = useState("");
  const [isMenuFormOverlayVisible, setIsMenuFormOverlayVisible] = useState(false);

  // ! Create Menu
  const toggleMenuFormOverlay = (mode = "create", businessId = "", menuId = "", menuItem = {}) => {
    console.log(mode, businessId, menuId);
    console.log(menuItem);
    if (mode === "create") {
      console.log("Menu Form overlay set to create mode");
      setMenuFormMode("create");
    } else if (mode === "update") {
      console.log("Menu Form overlay set to update mode");
      setMenuFormMode("update");
    }
    console.log("Toggle Menu Form");
    setupdateMenuInitialValues({ menuBusinessId: businessId, menuId: menuId, menuItem: menuItem });
    setMenuBusinessId(businessId);
    setIsMenuFormOverlayVisible(!isMenuFormOverlayVisible);
  };

  const createMenu = async (data, businessId) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/menu/" + businessId, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.ok) {
      fetchBusinesses();
      setMenuBusinessId(""); // reset the business id value stored.
    } else {
      console.log("Well, the creation of a new menu went very wrong!");
      console.log(responseData);
    }
    // todo: redirect the whole page to the menu builder page, where a user can add items for each menu item, and make a really cool looking menu
  };

  // ! UPDATE MENU
  const updateMenu = async (businessId, menuId, data) => {
    // console.log("Business ID: " + businessId);
    // console.log("Menu ID: " + menuId);
    // console.log(data);

    // console.log("updateMenuInitialValues:");
    // console.log(updateMenuInitialValues);

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/menu/" + businessId + "/" + menuId, {
      credentials: "include",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.ok) {
      fetchBusinesses();
      setMenuBusinessId(""); // reset the business id value stored.
    } else {
      console.log("Menu update broke");
      console.log(responseData);
    }
    console.log("menu updated");
  };

  // ! DELETE MENU
  // ! Handled within the component

  // & LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION

  // & LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION

  // & LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION LOCATION

  // States for the Location Form
  const [locationFormMode, setLocationFormMode] = useState("create");
  const [updateLocationInitialValues, setUpdateLocationInitialValues] = useState({ id: "", data: {} });
  const [locationBusinessId, setLocationBusinessId] = useState("");
  const [isLocationFormOverlayVisible, setIsLocationFormOverlayVisible] = useState(false);

  const toggleLocationFormOverlay = (mode = "create", businessId = "", locationId = "", locationItem) => {
    if (mode === "create") {
      console.log("Location Form overlay set to create mode");
      setLocationBusinessId(businessId);
      setLocationFormMode("create");
    } else if (mode === "update") {
      console.log("Location Form overlay set to update mode");
      setLocationBusinessId(businessId);
      setLocationFormMode("update");
    }
    console.log("Toggle Location Form");
    setUpdateLocationInitialValues({ locationBusinessId: businessId, locationId: locationId, locationItem: locationItem });
    setIsLocationFormOverlayVisible(!isLocationFormOverlayVisible);
  };

  // ! CREATE LOCATION
  const createLocation = async (data, businessId) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/locations/" + businessId, {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.ok) {
      fetchBusinesses();
      setLocationBusinessId("");
    } else {
      console.log("Location creation didn't go too well!");
      console.log(responseData);
    }
  };

  // ! UPDATE LOCATION
  const updateLocation = async (businessId, locationId, data) => {
    console.log("update location");
    console.log("Business ID: " + businessId);
    console.log("Location ID: " + locationId);
    console.log(data);

    console.log("updateLocationInitialValues:");
    console.log(updateLocationInitialValues);

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + /locations/ + businessId + "/" + locationId, {
      credentials: "include",
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.ok) {
      fetchBusinesses();
    } else {
      console.log("location update broke");
      console.log(responseData);
    }
    console.log("location updated");
  };

  // ! DELETE LOCATION
  // ! Handled within the component

  // ! Pull in the USER AND BUSINESS data into useEffect
  useEffect(() => {
    try {
      fetchUser();
      fetchBusinesses();
      // console.log("Data fetched");
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  const closeAllOverlays = () => {
    // console.log("Close all overlays triggered");
    setIsBusinessFormOverlayVisible(false);
    setIsMenuFormOverlayVisible(false);
    setIsLocationFormOverlayVisible(false);
    setIsImageUpdateFormVisible(false);
  };

  // & Change Image
  // & Change Image
  // & Change Image

  const [isImageUpdateFormVisible, setIsImageUpdateFormVisible] = useState(false);
  const [imageUploadDetails, setImageUploadDetails] = useState({});

  const changeImage = (type, businessId) => {
    setImageUploadDetails({ type, businessId });
    setIsImageUpdateFormVisible(true);
  };

  const closeUploadForm = () => {
    console.log("closing upload form");
    setIsImageUpdateFormVisible(false);
  };

  // & RETURN COMPONENTS
  // & RETURN COMPONENTS
  // & RETURN COMPONENTS

  // Load up the Business
  if (myBusinessLoading) {
    return <AccountLoading />;
  } else {
    return (
      <>
        {/* Account description at the top */}
        <div className='md:flex'>
          <div className='flex-grow'>
            <UserSubscriptionDetails user={userDetails} />
          </div>
          <div className=''>
            <button onClick={() => toggleBusinessFormOverlay("create")} className='button-no-margin mr-4 md:ml-0 ml-4 mb-4 flex items-center'>
              <span>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor' className='w-5 h-5'>
                  <path d='M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z' />
                </svg>
              </span>
              Business
            </button>
          </div>
        </div>

        {/* Business Listing Page */}
        <div className='py-2 md:py-4'>
          <BusinessList
            businesses={myBusinessDetails}
            fetchBusinesses={fetchBusinesses}
            createBusiness={createBusiness}
            updateBusiness={updateBusiness}
            setEditBusinessInitialValues={setEditBusinessInitialValues}
            toggleBusinessFormOverlay={toggleBusinessFormOverlay}
            toggleMenuFormOverlay={toggleMenuFormOverlay}
            toggleLocationFormOverlay={toggleLocationFormOverlay}
            menuBusinessId={menuBusinessId}
            setMenuBusinessId={setMenuBusinessId}
            updateMenu={updateMenu}
            changeImage={changeImage}
            closeUploadForm={closeUploadForm}
          />
        </div>

        {/* Forms */}
        {isBusinessFormOverlayVisible && (
          <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto'>
            <div className='p-4 m-auto'>
              <BusinessForm
                closeForm={closeAllOverlays}
                fetchBusinesses={fetchBusinesses}
                mode={businessFormMode}
                createBusiness={createBusiness}
                updateBusiness={updateBusiness}
                editBusinessInitialValues={editBusinessInitialValues}
                setEditBusinessInitialValues={setEditBusinessInitialValues}
                locationBusinessId={locationBusinessId}
              />
            </div>
          </div>
        )}

        {isMenuFormOverlayVisible && (
          <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto'>
            <div className='p-4 m-auto'>
              <MenuForm
                closeForm={closeAllOverlays}
                fetchBusinesses={fetchBusinesses}
                mode={menuFormMode}
                createMenu={createMenu}
                updateMenu={updateMenu}
                updateMenuInitialValues={updateMenuInitialValues}
                setupdateMenuInitialValues={setupdateMenuInitialValues}
                menuBusinessId={menuBusinessId}
                setMenuBusinessId={setMenuBusinessId}
              />
            </div>
          </div>
        )}

        {isLocationFormOverlayVisible && (
          <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto'>
            <div className='p-4 m-auto'>
              <LocationForm
                closeForm={closeAllOverlays}
                fetchBusinesses={fetchBusinesses}
                mode={locationFormMode}
                createLocation={createLocation}
                updateLocation={updateLocation}
                updateLocationInitialValues={updateLocationInitialValues}
                setUpdateLocationInitialValues={setUpdateLocationInitialValues}
                locationBusinessId={locationBusinessId}
                setLocationBusinessId={setLocationBusinessId}
              />
            </div>
          </div>
        )}

        {isImageUpdateFormVisible && (
          <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto'>
            <div className='p-4 m-auto bg-white'>
              <ImageUpload imageUploadDetails={imageUploadDetails} closeUploadForm={closeUploadForm} fetchBusinesses={fetchBusinesses} />
            </div>
          </div>
        )}
      </>
    );
  }
};

export default Account;
