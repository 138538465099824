import React from "react";

const LocationItem = (props) => {
  return (
    <a
      className='border border-black border-1 bg-white bg-opacity-95 rounded-2xl p-2 block mb-4 max-w-md mx-auto'
      href={process.env.REACT_APP_FRONTEND_URL + "/" + props.business.domainUri + "/" + props.location.location.uri}
    >
      <div className='font-bold text-lg'>{props.location.location.name}</div>
      <div className='text-sm'>
        {props.location.location.city}, {props.location.location.country}
      </div>
      <div className='text-sm'>
        <span className='text-red-500'>///</span>
        {props.location.location.what3words}
      </div>
    </a>
  );
};

export default LocationItem;
